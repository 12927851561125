import { FC } from "react";

import { TitleStatusEplTest } from "~/routes/suivi/components/TitleStatusEplTest.tsx";
import { TitleStatusPilotest } from "~/routes/suivi/components/TitleStatusPilotest.tsx";
import { UploadCsvBox } from "~/routes/suivi/components/UploadCsvBox.tsx";
import { useAttempts } from "~/routes/suivi/providers/AttemptsProvider.tsx";
import { usePilotestCredentials } from "~/routes/suivi/providers/PilotestCredentialsProvider.tsx";
import { useSettings } from "~/routes/suivi/providers/SettingsProvider.tsx";

export const Title: FC = () => {
  const { settings } = useSettings();
  const { savePilotestAttempts } = useAttempts();
  const { hasPilotestAutoSyncEnabled } = usePilotestCredentials();

  if (!settings) {
    return null;
  }

  return (
    <div className="flex justify-between mb-4">
      <div className="flex flex-col space-y-1.5">
        <TitleStatusPilotest />
        <TitleStatusEplTest />
      </div>
      {!hasPilotestAutoSyncEnabled && (
        <UploadCsvBox
          onResults={async (source, results) => {
            if (!results) {
              return;
            }
            await savePilotestAttempts(source, results);
          }}
        />
      )}
    </div>
  );
};
