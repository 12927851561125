import { useCallback } from "react";

import { Exercise } from "~/exercise.ts";
import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { supabase } from "~/supabase.ts";
import { Tables } from "~/supabase.types.ts";

export type BasicScore = Pick<Tables<"scores">, "success" | "timing">;

export const useScores = () => {
  const { user } = useCurrentUser();

  const saveScore = useCallback(
    async (exercise: Exercise, variant: string, score: BasicScore) =>
      await supabase.from("scores").insert({
        user_id: user.id,
        exercise,
        variant: variant.toLocaleLowerCase(),
        ...score,
        at: new Date().toISOString(),
      }),
    [user.id],
  );

  const getScoresForExercise = useCallback(
    async (exercise: Exercise) => {
      const { data } = await supabase
        .from("scores")
        .select("exercise, variant, success, timing, at")
        .eq("user_id", user.id)
        .eq("exercise", exercise)
        .order("at", { ascending: true });

      return data ?? [];
    },
    [user.id],
  );

  const getScoresCountForExercise = useCallback(
    async (exercise: Exercise) => {
      const { count } = await supabase
        .from("scores")
        .select("*", { count: "exact", head: true })
        .eq("user_id", user.id)
        .eq("exercise", exercise);

      return count ?? 0;
    },
    [user.id],
  );

  const getScoresForVariant = useCallback(
    async (exercise: string, variant: string) => {
      const { data } = await supabase
        .from("scores")
        .select("exercise, variant, success, timing, at")
        .eq("user_id", user.id)
        .eq("exercise", exercise)
        .eq("variant", variant)
        .order("at", { ascending: true });

      return data ?? [];
    },
    [user.id],
  );

  return {
    saveScore,
    getScoresForExercise,
    getScoresCountForExercise,
    getScoresForVariant,
  };
};
