import { FC, Fragment, useEffect, useState } from "react";

import { Button } from "~/components/Button.tsx";
import { TitleLabel } from "~/components/TitleLabel.tsx";
import { Exercise } from "~/exercise.ts";
import { useClock } from "~/hooks/useClock.ts";
import { useScores } from "~/hooks/useScores.ts";
import { IndexedElement } from "~/routes/nombres-premiers/numbers.ts";

export interface TrainingBaseViewProps {
  indexedElements: IndexedElement[];
  withChrono?: boolean;
}

export const TrainingBaseView: FC<TrainingBaseViewProps> = ({
  indexedElements = [],
  withChrono = false,
}) => {
  const { saveScore } = useScores();

  const { clock, restartClock, stopClock } = useClock({
    interval: withChrono ? 3 : 0,
    onRestart: () => handleClickAnswer("PASS"),
  });

  const [currentElement, setCurrentElement] = useState<
    IndexedElement | undefined
  >();
  const [pastElements, setPastElements] = useState<IndexedElement[]>([]);

  useEffect(() => {
    if (indexedElements.length) {
      setCurrentElement(indexedElements[0]);
    }

    setPastElements([]);
  }, [indexedElements]);

  const handleClickAnswer = (answer: string) => {
    if (!currentElement) {
      stopClock();
      return;
    }

    const success =
      (currentElement.isCorrect && answer === "PRIME") ||
      (!currentElement.isCorrect && answer === "COMPOSED");

    saveScore(Exercise.NombresPremiers, "default", { success, timing: 0 });

    setPastElements((prev) => [
      ...prev,
      {
        ...currentElement,
        status: success ? "won" : "lost",
      },
    ]);

    setCurrentElement(indexedElements[currentElement.index + 1]);

    restartClock();
  };

  return (
    <Fragment>
      {currentElement && (
        <div className="grid grid-cols-1 gap-4">
          <div>
            <TitleLabel className="mb-1">Nombre</TitleLabel>
            <div className="overflow-hidden rounded-lg bg-slate-50 h-32">
              <div className="px-3 py-3 h-full flex justify-center items-center text-6xl font-semibold">
                {currentElement.element}
              </div>
            </div>
          </div>
          <div>
            <h2 className="mb-1 flex justify-between">
              <TitleLabel>Réponse</TitleLabel>
              {clock !== 0 && (
                <span
                  className="text-slate-500 tabular-nums cursor-pointer text-sm"
                  title="Désactiver le chronomètre"
                  onClick={() => stopClock()}
                >
                  ⌛ {clock}
                </span>
              )}
            </h2>
            <div className="overflow-hidden rounded-lg bg-slate-50">
              <div className="px-4 py-4 grid grid-cols-2 gap-4">
                <Button onClick={() => handleClickAnswer("PRIME")}>
                  Premier
                </Button>
                <Button onClick={() => handleClickAnswer("COMPOSED")}>
                  Composé
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {pastElements.length > 0 && (
        <div className="col-span-2 mt-12">
          <h2 className="mb-2 uppercase font-semibold flex justify-between">
            <span>Résultats</span>
            <span>
              {pastElements.filter((c) => c.status === "won").length}{" "}
              ✅&nbsp;&nbsp;/&nbsp;&nbsp;
              {pastElements.filter((c) => c.status === "lost").length} ❌
            </span>
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-5 gap-2 mb-8">
            {[...pastElements].reverse().map((w, idx) => (
              <div
                key={`past-${idx}`}
                className={`flex items-center space-x-2 px-2 py-2 rounded ${
                  w.status === "won" ? "bg-green-100" : "bg-red-100"
                }`}
              >
                {w.element}
                &nbsp;:&nbsp;
                <span className="font-semibold">
                  {w.isCorrect ? "Premier" : "Composé"}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
    </Fragment>
  );
};
