import { useAtom } from "jotai";
import { FC } from "react";

import { BannerSupportScores } from "~/components/BannerSupportScores.tsx";
import { ScoreButton } from "~/components/ScoreButton.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select.tsx";
import { Wrapper } from "~/components/Wrapper.tsx";
import { Exercise } from "~/exercise.ts";
import { cm1TemplateAtom } from "~/routes/cm1/atoms.ts";
import { CM1_TEMPLATES } from "~/routes/cm1/templates.ts";
import { TrainingBaseView } from "~/routes/cm1/TrainingBaseView.tsx";

export const Cm1: FC = () => {
  const [template, setTemplate] = useAtom(cm1TemplateAtom);

  return (
    <Wrapper
      header={
        <div className="flex items-center justify-center gap-3">
          <ScoreButton
            exercise={Exercise.CM1}
            variant={template}
            label={`CM1 – ${template}`}
            withTimingReferenceLine={
              template === "9 opérations (KD)" ? 25 : undefined
            }
          />
          <Select value={template} onValueChange={setTemplate}>
            <SelectTrigger>
              <SelectValue placeholder="Sélectionner un mode…" />
            </SelectTrigger>
            <SelectContent>
              {CM1_TEMPLATES.map((template, idx) => (
                <SelectItem key={`option-${idx}`} value={template.name}>
                  {template.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      }
    >
      <BannerSupportScores exercise={Exercise.CM1} />
      <TrainingBaseView template={template} />
    </Wrapper>
  );
};
