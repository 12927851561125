import { clsx } from "clsx";
import { BotIcon, MailIcon } from "lucide-react";
import { FC } from "react";

interface AdminUserProps {
  user_id: string;
  provider: string;
  email: string;
  discord_username: string;
  onClick?: (userId: string) => void;
  className?: string;
  size?: "md" | "lg";
}

export const AdminUser: FC<AdminUserProps> = ({
  user_id,
  provider,
  email,
  discord_username,
  onClick,
  className,
  size = "md",
}) => {
  if (provider === "email") {
    return (
      <span
        onClick={onClick ? () => onClick(user_id) : undefined}
        className={clsx(
          "inline-flex gap-2 items-center",
          onClick && "cursor-pointer hover:underline underline-offset-2",
          className,
        )}
      >
        <MailIcon
          className={clsx(
            "text-green-500",
            size === "md" ? "w-4 h-4" : "w-5 h-5",
          )}
        />{" "}
        {email}
      </span>
    );
  }

  return (
    <span
      onClick={onClick ? () => onClick(user_id) : undefined}
      className={clsx(
        "inline-flex gap-2 items-center",
        onClick && "cursor-pointer hover:underline underline-offset-2",
        className,
      )}
    >
      <BotIcon
        className={clsx(
          "text-[#5865F2]",
          size === "md" ? "w-4 h-4" : "w-5 h-5",
        )}
      />{" "}
      {discord_username}
    </span>
  );
};
