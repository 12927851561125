import clsx from "clsx";
import { format } from "date-fns";
import { orderBy } from "lodash";
import { FC } from "react";
import {
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  XAxis,
  YAxis,
} from "recharts";

import { Drawer, DrawerProps } from "~/components/Drawer.tsx";
import { ExerciseWithIcon } from "~/routes/suivi/components/ExerciseWithIcon.tsx";
import { useAttempts } from "~/routes/suivi/providers/AttemptsProvider.tsx";
import { useRenamings } from "~/routes/suivi/providers/RenamingsProvider.tsx";
import { backgroundClassNameFromStanineClass } from "~/routes/suivi/utils/backgroundClassNameFromStanineClass.ts";
import { fillClassNameFromStanineClass } from "~/routes/suivi/utils/fillClassNameFromStanineClass.ts";

interface DrawerExerciseDetailsProps
  extends Omit<DrawerProps, "title" | "children"> {
  exerciseName: string | null;
}

export const DrawerExerciseDetails: FC<DrawerExerciseDetailsProps> = ({
  exerciseName,
  open,
  onDismiss,
}) => {
  const { attempts } = useAttempts();
  const { renamingsList } = useRenamings();

  const processedAttempts = orderBy(
    attempts.filter((a) => a.name === exerciseName),
    ["at"],
    ["asc"],
  );

  return (
    <Drawer
      open={open}
      onDismiss={onDismiss}
      title={
        <p className="inline-flex items-center gap-3">
          <ExerciseWithIcon
            exerciseName={exerciseName ?? ""}
            customName={renamingsList[exerciseName ?? ""]}
            withLink={false}
            size="lg"
          />
        </p>
      }
    >
      {exerciseName && (
        <>
          <div className="w-full">
            <ResponsiveContainer width="100%" height={292}>
              <ScatterChart
                margin={{ top: 8, right: 16, bottom: 8, left: -32 }}
              >
                <CartesianGrid stroke="#e5e5e5" vertical={false} />
                <XAxis
                  dataKey="at"
                  tickFormatter={(time) => format(time, "d MMM yy")}
                  axisLine={false}
                  tickLine={false}
                  tick={{ fontSize: 12, fill: "#888" }}
                  dy={5}
                />
                <YAxis dataKey="stanineClass" domain={[1, 9]} />
                <YAxis
                  width={18}
                  ticks={[1, 2, 3, 4, 5, 6, 7, 8, 9]}
                  domain={[1, 9]}
                  axisLine={false}
                  tickLine={false}
                  tick={{ fontSize: 14, fill: "#888" }}
                  dx={-5}
                />
                <Scatter name="Scores" data={processedAttempts} fill="black">
                  {processedAttempts.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      className={fillClassNameFromStanineClass(
                        entry.stanineClass,
                      )}
                    />
                  ))}
                </Scatter>
              </ScatterChart>
            </ResponsiveContainer>
          </div>
          <div className="mt-6">
            <h2 className="font-medium text-xl mb-4">
              Détails ({processedAttempts?.length} essais)
            </h2>
            <div className="mt-1 mb-6 flow-root">
              <table className="min-w-full">
                <tbody className="bg-white">
                  {processedAttempts
                    ?.slice()
                    .reverse()
                    .map((attempt, idx) => (
                      <tr key={idx} className="odd:bg-slate-50">
                        <td className="whitespace-nowrap py-1.5 pl-2 text-xs text-slate-500">
                          {format(attempt.at, "eeee d MMM y à HH:mm:ss")}
                        </td>
                        <td className="whitespace-nowrap px-2 py-1 text-xs text-slate-900 tabular-nums flex justify-end items-center gap-4">
                          <span>{attempt.percentScore} %</span>
                          <span
                            className={clsx(
                              "px-4 py-1 rounded",
                              backgroundClassNameFromStanineClass(
                                attempt.stanineClass,
                              ),
                            )}
                          >
                            {attempt.stanineClass}
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </Drawer>
  );
};
