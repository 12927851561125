import clsx from "clsx";
import { FC } from "react";

import { ExercisesResultsMinMaxAvg } from "~/routes/suivi/components/ExercisesResultsMinMaxAvg.tsx";
import { ExerciseResult } from "~/routes/suivi/types/ExerciseResult.ts";
import { textClassNameFromPercentScore } from "~/routes/suivi/utils/textClassNameFromPercentScore.ts";
import { Enums } from "~/supabase.types.ts";

interface ExercisesResultsResultsProps {
  result: ExerciseResult;
  display?: Enums<"graph_display">;
  className?: string;
}

export const ExercisesResultsResults: FC<ExercisesResultsResultsProps> = ({
  result,
  display,
  className,
}) => (
  <div
    className={clsx(
      className,
      "w-[128px] h-6 items-center justify-end hidden last-of-type:flex sm:flex",
    )}
  >
    {result.count > 0 &&
      (display?.includes("stanine") ? (
        <div className="flex items-center justify-end gap-2">
          <p
            className="font-mono text-xs text-slate-400 tabular-nums"
            title="Tentatives"
          >
            {result.count}
          </p>
          <ExercisesResultsMinMaxAvg
            min={result.min}
            max={result.max}
            avg={result.averageStanineClass}
          />
        </div>
      ) : (
        <p className="flex items-center justify-between tabular-nums font-mono text-sm">
          <span className="text-xs mr-2 text-slate-400" title="Tentatives">
            ({result.count})
          </span>
          <span
            className={textClassNameFromPercentScore(
              result.averagePercentScore,
            )}
          >
            {result.averagePercentScore} %
          </span>
        </p>
      ))}
  </div>
);
