import { FC, useState } from "react";

import { BannerSupportScores } from "~/components/BannerSupportScores.tsx";
import { ScoreButton } from "~/components/ScoreButton.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select.tsx";
import { Wrapper } from "~/components/Wrapper.tsx";
import { Exercise } from "~/exercise.ts";
import { TrainingBaseView } from "~/routes/tangram/TrainingBaseView.tsx";

type APP_MODE = "FREE_TRAINING" | "TIMED_TRAINING";

export const Tangram: FC = () => {
  const [mode, setMode] = useState<APP_MODE>("FREE_TRAINING");

  const handleOnChangeMode = (mode: string) => {
    setMode(mode as APP_MODE);
  };

  return (
    <Wrapper
      extended={true}
      header={
        <div className="flex items-center justify-center gap-3">
          <ScoreButton
            exercise={Exercise.Tangram}
            variant="guess-all"
            label="Tangram"
          />
          <Select value={mode} onValueChange={handleOnChangeMode}>
            <SelectTrigger>
              <SelectValue placeholder="Sélectionner un mode…" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="FREE_TRAINING">Entrainement</SelectItem>
            </SelectContent>
          </Select>
        </div>
      }
    >
      <BannerSupportScores exercise={Exercise.Tangram} />
      <TrainingBaseView withChrono={mode === "TIMED_TRAINING"} />
    </Wrapper>
  );
};
