import { atom } from "jotai";

import { ExerciseAttempt } from "~/routes/suivi/types/ExerciseAttempt.ts";

export const suiviPilotestAttempts = atom<ExerciseAttempt[] | null>(null);

export const suiviEplTestAttempts = atom<ExerciseAttempt[] | null>(null);

export const suiviAllAttempts = atom((get) => [
  ...(get(suiviPilotestAttempts) ?? []),
  ...(get(suiviEplTestAttempts) ?? []),
]);
