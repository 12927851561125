import { HeartIcon } from "lucide-react";
import { FC, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { supabase } from "~/supabase.ts";

export const Donate: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { user } = useCurrentUser();

  useEffect(() => {
    if (searchParams.has("sid")) {
      supabase
        .from("donations")
        .insert({
          user_id: user.id,
          stripe_checkout_session_id: searchParams.get("sid") ?? "",
        })
        .then(() => {
          searchParams.delete("sid");
          setSearchParams(searchParams);
        });
    }
  }, [searchParams, setSearchParams, user.id]);

  return (
    <div className="flex flex-col items-center justify-center h-full">
      <p>
        <HeartIcon className="text-blue-600" size={72} />
      </p>
      <h2 className="mt-4 text-2xl font-medium">Merci beaucoup !</h2>
      <p className="mt-2 max-w-md text-center">
        N'hésitez pas à transmettre vos idées, retours
        <br />
        et pistes d'amélioration via{" "}
        <a
          href="https://forms.fillout.com/t/vJdHp6jTEqus"
          target="_blank"
          className="underline hover:no-underline"
        >
          le formulaire de contact
        </a>{" "}
        :)
      </p>
    </div>
  );
};
