import { FC } from "react";

import { Drawer } from "~/components/Drawer.tsx";
import { GroupsBox } from "~/routes/suivi/components/GroupsBox.tsx";

interface GroupsDrawerProps {
  open: boolean;
  onDismiss: () => void;
}

export const DrawerGroups: FC<GroupsDrawerProps> = ({ open, onDismiss }) => (
  <Drawer open={open} onDismiss={onDismiss} title="Modifier les groupes">
    <GroupsBox />
  </Drawer>
);
