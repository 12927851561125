import { QueryData } from "@supabase/supabase-js";

import { supabase } from "~/supabase.ts";

export const getSettingsQuery = (userId: string) =>
  supabase
    .from("suivi_settings")
    .select("*, current_group:suivi_groups(*)")
    .eq("user_id", userId)
    .limit(1)
    .maybeSingle();

export type SettingsWithCurrentGroup = QueryData<
  ReturnType<typeof getSettingsQuery>
>;

export type SettingsGroup = NonNullable<
  SettingsWithCurrentGroup["current_group"]
>;
