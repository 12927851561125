import { format } from "date-fns";
import { take } from "lodash";
import { FC, ReactNode, useEffect, useState } from "react";

import { Drawer } from "~/components/Drawer.tsx";
import { AdminBlock } from "~/routes/admin/components/AdminBlock.tsx";
import { AdminUser } from "~/routes/admin/components/AdminUser.tsx";
import { GetUserInfoResponseType } from "~/routes/admin/types.ts";
import { supabase } from "~/supabase.ts";
import { Tables } from "~/supabase.types.ts";
import { formatDate } from "~/utils/formatDate.ts";
import { formatNumber } from "~/utils/formatNumber.ts";

interface AdminDrawerUserProps {
  userId: string | null;
  onDismiss: () => void;
}

const AdminDrawerUserHeader = ({
  label,
  value,
}: {
  label: string;
  value?: ReactNode;
}) => {
  return (
    <div className="flex items-center gap-2">
      <div className="w-4/12 text-right text-[10px] uppercase font-semibold text-slate-400">
        {label}
      </div>
      <div className="w-8/12 text-sm tabular-nums">{value ?? "–"}</div>
    </div>
  );
};

export const AdminDrawerUser: FC<AdminDrawerUserProps> = ({
  userId,
  onDismiss,
}) => {
  const [user, setUser] = useState<GetUserInfoResponseType | null>(null);
  const [lastImports, setLastImports] = useState<
    Tables<"suivi_imports">[] | null
  >(null);
  const [lastAttempts, setLastAttempts] = useState<
    Tables<"suivi_attempts">[] | null
  >(null);
  const [lastScores, setLastScores] = useState<Tables<"scores">[] | null>(null);
  const [suiviSettings, setSuiviSettings] =
    useState<Tables<"suivi_settings"> | null>(null);
  const [renamings, setRenamings] = useState<
    Tables<"suivi_renamings">[] | null
  >(null);
  const [flagged, setFlagged] = useState<
    Tables<"suivi_flagged_exercises">[] | null
  >(null);
  const [groups, setGroups] = useState<Tables<"suivi_groups">[] | null>(null);
  const [dimensions, setDimensions] = useState<string[] | null>(null);

  useEffect(() => {
    if (!userId) {
      return;
    }

    supabase
      .rpc("private_get_user_info", { the_user_id: userId ?? "" })
      .maybeSingle()
      .then(({ data }) => setUser(data ?? null));

    supabase
      .from("suivi_imports")
      .select()
      .eq("user_id", userId)
      .order("at", { ascending: false })
      .then(({ data }) => setLastImports(data ?? []));

    supabase
      .from("suivi_attempts")
      .select()
      .eq("user_id", userId)
      .order("at", { ascending: false })
      .then(({ data }) => setLastAttempts(data ?? []));

    supabase
      .from("scores")
      .select()
      .eq("user_id", userId)
      .order("at", { ascending: false })
      .then(({ data }) => setLastScores(data ?? []));

    supabase
      .from("suivi_settings")
      .select()
      .eq("user_id", userId)
      .maybeSingle()
      .then(({ data }) => setSuiviSettings(data ?? null));

    supabase
      .from("suivi_renamings")
      .select()
      .eq("user_id", userId)
      .then(({ data }) => setRenamings(data ?? []));

    supabase
      .from("suivi_flagged_exercises")
      .select()
      .eq("user_id", userId)
      .then(({ data }) => setFlagged(data ?? []));

    supabase
      .from("suivi_groups")
      .select()
      .eq("user_id", userId)
      .order("created_at", { ascending: true })
      .then(({ data }) => setGroups(data ?? []));

    supabase
      .from("suivi_dimensions")
      .select("*, group:suivi_groups(name, exercises)")
      .eq("user_id", userId)
      .order("created_at", { ascending: true })
      .then(({ data }) => setDimensions(data?.map((d) => d.group!.name) ?? []));
  }, [userId]);

  return (
    <Drawer
      open={!!userId}
      title={user ? <AdminUser {...user} className="text-xl" size="lg" /> : ""}
      onDismiss={onDismiss}
      blurBackground={true}
    >
      {user && (
        <>
          <div className="flex flex-col gap-2 border-2 rounded py-3">
            <AdminDrawerUserHeader label="ID" value={user?.user_id} />
            <AdminDrawerUserHeader
              label="Inscrit le"
              value={formatDate(user.created_at)}
            />
            <AdminDrawerUserHeader
              label="Dernier login"
              value={formatDate(user.last_sign_in_at)}
            />
            <AdminDrawerUserHeader label="Email" value={user?.email} />
            <AdminDrawerUserHeader
              label="Discord"
              value={user?.discord_username}
            />
          </div>
          <div className="flex flex-col gap-6 my-6">
            {suiviSettings && (
              <AdminBlock
                title="Outil de Suivi"
                subtitle={`Activé le ${format(new Date(suiviSettings?.created_at), "dd/MM/yy HH:mm")}`}
              >
                <div className="flex flex-col gap-2 px-4 py-3">
                  {suiviSettings?.display_from_date && (
                    <AdminDrawerUserHeader
                      label="Depuis"
                      value={formatDate(
                        suiviSettings.display_from_date,
                        "dateLong",
                      )}
                    />
                  )}
                  {suiviSettings.epl_api_key && (
                    <AdminDrawerUserHeader
                      label="Clé API EPLtest"
                      value={suiviSettings?.epl_api_key}
                    />
                  )}
                  {suiviSettings?.table_columns && (
                    <AdminDrawerUserHeader
                      label="Colonnes"
                      value={suiviSettings?.table_columns.join(", ")}
                    />
                  )}
                  {suiviSettings?.table_display && (
                    <AdminDrawerUserHeader
                      label="Affichage Progression"
                      value={suiviSettings?.table_display}
                    />
                  )}
                  {suiviSettings?.graph_display && (
                    <AdminDrawerUserHeader
                      label="Affichage Graphs"
                      value={suiviSettings?.graph_display}
                    />
                  )}
                  {suiviSettings?.graph_compute && (
                    <AdminDrawerUserHeader
                      label="Calcul Graphs"
                      value={suiviSettings?.graph_compute}
                    />
                  )}
                  {(renamings?.length ?? 0) > 0 && (
                    <AdminDrawerUserHeader
                      label="Renommages"
                      value={renamings
                        ?.map((ren) => ren.exercise_renamed)
                        .join(", ")}
                    />
                  )}
                  {(flagged?.length ?? 0) > 0 && (
                    <AdminDrawerUserHeader
                      label="Highlights"
                      value={flagged?.map((flag) => flag.name).join(", ")}
                    />
                  )}
                  {(groups?.length ?? 0) > 0 && (
                    <AdminDrawerUserHeader
                      label="Groupes"
                      value={groups?.map((group) => group.name).join(", ")}
                    />
                  )}
                  {(dimensions?.length ?? 0) > 0 && (
                    <AdminDrawerUserHeader
                      label="Dimensions"
                      value={dimensions?.join(", ")}
                    />
                  )}
                </div>
              </AdminBlock>
            )}
            {(lastImports?.length ?? 0) > 0 && (
              <AdminBlock
                title="Derniers Imports"
                subtitle={`${lastImports?.length} imports réalisés`}
              >
                <table className="w-full">
                  <tbody>
                    {take(
                      lastImports?.map((anImport, idx) => (
                        <tr key={`import-${idx}`} className="even:bg-slate-50">
                          <td className="text-right tabular-nums text-sm px-2 py-1 w-3/12">
                            {formatDate(anImport.at)}
                          </td>
                          <td className="px-2 text-sm">{anImport.source}</td>
                          <td className="px-2 text-sm text-right font-mono tabular-nums">
                            +{formatNumber(anImport.attempts_added)}
                          </td>
                        </tr>
                      )),
                      5,
                    )}
                  </tbody>
                </table>
              </AdminBlock>
            )}
            {(lastAttempts?.length ?? 0) > 0 && (
              <AdminBlock
                title="Derniers Résultats Pilotest"
                subtitle={`${formatNumber(lastAttempts?.length)} essais réalisés`}
              >
                <table className="w-full">
                  <tbody>
                    {take(
                      lastAttempts?.map((attempt, idx) => (
                        <tr key={`import-${idx}`} className="even:bg-slate-50">
                          <td className="text-right tabular-nums text-sm px-2 py-1 w-3/12">
                            {formatDate(attempt.at)}
                          </td>
                          <td className="px-2 text-sm">{attempt.exercise}</td>
                          <td className="px-2 text-sm tabular-nums font-mono text-right">
                            <span className="text-xs">
                              ({attempt.percent_score}%)
                            </span>{" "}
                            <span className="font-semibold">
                              {attempt.stanine_class}
                            </span>
                          </td>
                        </tr>
                      )),
                      10,
                    )}
                  </tbody>
                </table>
              </AdminBlock>
            )}
            {(lastScores?.length ?? 0) > 0 && (
              <AdminBlock
                title="Derniers Scores"
                subtitle={`${formatNumber(lastScores?.length)} exercices réalisés`}
              >
                <table className="w-full">
                  <tbody>
                    {take(
                      lastScores?.map((score, idx) => (
                        <tr key={`import-${idx}`} className="even:bg-slate-50">
                          <td className="text-right tabular-nums text-sm px-2 py-1 w-3/12">
                            {formatDate(score.at)}
                          </td>
                          <td className="px-2 text-sm">
                            {score.exercise}{" "}
                            <span className="text-slate-400">
                              {score.variant}
                            </span>
                          </td>
                        </tr>
                      )),
                      10,
                    )}
                  </tbody>
                </table>
              </AdminBlock>
            )}
          </div>
        </>
      )}
    </Drawer>
  );
};
