import { last } from "lodash";
import { useMatches } from "react-router-dom";

import { RouteHandle } from "~/types/RouteHandle.ts";

export const useMatchingRoute = () => {
  const matchingRoutes = useMatches();

  return last(matchingRoutes)?.handle as RouteHandle;
};
