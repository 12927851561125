import { FC, useEffect, useState } from "react";

import { BannerSupport } from "~/components/BannerSupport.tsx";
import { Exercise } from "~/exercise.ts";
import { useScores } from "~/hooks/useScores.ts";

interface BannerSupportScoresProps {
  exercise: Exercise;
  threshold?: number;
}

export const BannerSupportScores: FC<BannerSupportScoresProps> = ({
  exercise,
  threshold = 10,
}) => {
  const { getScoresCountForExercise } = useScores();

  const [scoresCount, setScoresCount] = useState<number | null>(null);

  useEffect(() => {
    getScoresCountForExercise(exercise).then(setScoresCount);
  }, [exercise, getScoresCountForExercise]);

  if (scoresCount === null || scoresCount <= threshold) {
    return null;
  }

  return (
    <BannerSupport>
      Depuis votre inscription, vous avez réalisé{" "}
      <span className="font-semibold">{scoresCount} essais</span> sur cet outil
      proposé par KD Tools !
    </BannerSupport>
  );
};
