import { differenceInDays } from "date-fns";
import { HeartHandshakeIcon } from "lucide-react";
import { FC, ReactNode } from "react";

import { LinkDonate } from "~/components/LinkDonate.tsx";
import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { useDonations } from "~/providers/DonationsProvider.tsx";

interface BannerSupportProps {
  children: ReactNode;
}

export const BannerSupport: FC<BannerSupportProps> = ({ children }) => {
  const { user } = useCurrentUser();
  const { donations } = useDonations();

  if (
    differenceInDays(new Date(), new Date(user.created_at)) <= 14 ||
    donations.length > 0
  ) {
    return null;
  }

  return (
    <div className="flex flex-col border-2 border-blue-600 rounded px-5 py-4 mb-4 sm:mb-10 sm:shadow-md bg-blue-50">
      <p className="text-xl font-medium flex justify-between text-blue-600">
        <span>Soutenir KD Tools</span>
        <HeartHandshakeIcon className="w-8 h-8" />
      </p>
      <p className="text-sm mt-1">{children}</p>
      <p className="text-sm mt-1">
        Si les outils disponibles vous sont utiles, n'hésitez pas à{" "}
        <LinkDonate wrapperClassname="font-semibold text-blue-600">
          soutenir le projet
        </LinkDonate>{" "}
        pour assurer sa pérénité.
      </p>
      <p className="text-xs mt-2">
        <LinkDonate wrapperClassname="text-slate-700">
          Ne plus afficher
        </LinkDonate>
      </p>
    </div>
  );
};
