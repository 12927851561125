export type MemoryCalculsOperation = {
  index: number;
  operation: string;
  result: number;
};

export const generateOperations = (
  length: number,
  variant: 2 | 3 = 2,
  randomFactor = 0.4,
): MemoryCalculsOperation[] => {
  // Non-zero multiples of five from -50 to 50
  const multiplesOfFive: number[] = [];
  for (let i = -50; i <= 50; i += 5) {
    if (i !== 0) {
      multiplesOfFive.push(i);
    }
  }

  const generateOperation = (
    desiredResult?: number,
    excludeOperation?: string,
  ): MemoryCalculsOperation => {
    const operators = ["+", "-"];

    let result: number;
    if (desiredResult === undefined) {
      // Pick a random non-zero multiple of 5 as the result
      result =
        multiplesOfFive[Math.floor(Math.random() * multiplesOfFive.length)];
    } else {
      result = desiredResult;
    }

    // Try multiple times to find suitable (a, b, operator)
    for (let attempts = 0; attempts < 100; attempts++) {
      const operator = operators[Math.floor(Math.random() * operators.length)];
      const a =
        multiplesOfFive[Math.floor(Math.random() * multiplesOfFive.length)];

      let b: number;
      if (operator === "+") {
        // result = a + b => b = result - a
        b = result - a;
      } else {
        // result = a - b => b = a - result
        b = a - result;
      }

      // Check that b is a non-zero multiple of 5 and present in our multiples array
      if (b !== 0 && b % 5 === 0 && multiplesOfFive.includes(b)) {
        // Normalize the operation string to ensure only one sign
        let operationStr: string;
        if (b < 0) {
          // If b is negative, adjust the displayed operator
          if (operator === "+") {
            // a + (-x) => a - x
            operationStr = `${a}-${Math.abs(b)}`;
          } else {
            // a - (-x) => a + x
            operationStr = `${a}+${Math.abs(b)}`;
          }
        } else {
          // b is positive
          operationStr = `${a}${operator}${b}`;
        }

        if (excludeOperation && operationStr === excludeOperation) {
          continue;
        }

        return { index: -1, operation: operationStr, result };
      }
    }

    throw new Error(
      "Could not generate a suitable distinct operation after many attempts.",
    );
  };

  const arr: MemoryCalculsOperation[] = [];
  for (let i = 0; i < length; i++) {
    if (i < variant) {
      // First variant elements: random operations
      arr.push(generateOperation());
    } else {
      // Subsequent elements: randomFactor% chance to reuse the result from i-variant
      const shouldRepeat = Math.random() > randomFactor;
      if (shouldRepeat) {
        const prevResult = arr[i - variant].result;
        arr.push(generateOperation(prevResult, arr[i - variant].operation));
      } else {
        arr.push(generateOperation());
      }
    }
  }

  return arr.map((op, index) => ({ ...op, index }));
};
