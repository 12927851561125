import { meanBy, orderBy, sumBy, take } from "lodash";

import { DimensionWithExercises } from "~/routes/suivi/queries/dimensionsWithExercisesQuery.ts";
import {
  DimensionWithAverages,
  DimensionWithAveragesExercise,
} from "~/routes/suivi/types/DimensionWithAverages.ts";
import { ExerciseAttempt } from "~/routes/suivi/types/ExerciseAttempt.ts";
import { htmlDateToDate } from "~/routes/suivi/utils/htmlDateToDate.ts";

export const computeDimensionWithAverages = (
  dimensions: DimensionWithExercises["dimensions"],
  attempts: ExerciseAttempt[],
  compute: DimensionWithExercises["compute"],
  fromDate: string | null,
): DimensionWithAverages[] =>
  dimensions.map((dimension) => {
    // For each dimension, calculate averages for each exercise…
    const exercises = dimension.exercises.map((exercise) => {
      const fromDateDate = htmlDateToDate(fromDate ?? "");

      // Filter attempts by exercise name
      const exerciseAttempts = attempts.filter(
        (attempt) =>
          attempt.name === exercise.name &&
          (!fromDateDate || attempt.at >= fromDateDate),
      );

      // Sort attempts by date (newest first)
      const sortedAttempts = orderBy(exerciseAttempts, ["at"], ["desc"]);
      // Take the last X attempts
      const lastAttempts = take(
        sortedAttempts,
        compute === "last_attempt"
          ? 1
          : compute === "average_3_attempts"
            ? 3
            : compute === "average_10_attempts"
              ? 10
              : 5,
      );

      // Compute average percentScore and stanineClass
      const averagePercentScore = lastAttempts.length
        ? meanBy(lastAttempts, "percentScore")
        : 0;
      const averageStanineClass = lastAttempts.length
        ? meanBy(lastAttempts, "stanineClass")
        : 0;

      return {
        ...exercise,
        averagePercentScore,
        averageStanineClass,
      } as DimensionWithAveragesExercise;
    });

    // Filter out exercises with no attempts (averagePercentScore and averageStanineClass of 0)
    const validExercises = exercises.filter(
      (ex) => ex.averagePercentScore > 0 || ex.averageStanineClass > 0,
    );

    // Calculate the dimension-level averages (averaging across valid exercises)
    const totalPercentScore = sumBy(validExercises, "averagePercentScore");
    const totalStanineClass = sumBy(validExercises, "averageStanineClass");

    const averagePercentScore =
      validExercises.length > 0 ? totalPercentScore / validExercises.length : 0;
    const averageStanineClass =
      validExercises.length > 0 ? totalStanineClass / validExercises.length : 0;

    // Return the dimension-level averages and per-exercise details
    return {
      ...dimension,
      averagePercentScore,
      averageStanineClass,
      exercises: validExercises,
    };
  });
