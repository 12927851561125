import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import { LayoutLoading } from "~/components/LayoutLoading.tsx";
import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import {
  getSettingsQuery,
  SettingsWithCurrentGroup,
} from "~/routes/suivi/queries/settingsQuery.ts";
import { supabase } from "~/supabase.ts";
import { Tables } from "~/supabase.types.ts";

interface SettingsContextType {
  settings: SettingsWithCurrentGroup | null;
  registerSettings: (
    settings: Partial<Tables<"suivi_settings">>,
  ) => Promise<void>;
  saveSettings: (settings: Partial<Tables<"suivi_settings">>) => Promise<void>;
  refreshSettings: () => Promise<void>;
}

const SettingsContext = createContext<SettingsContextType | undefined>(
  undefined,
);

export const useSettings = () => {
  const context = useContext(SettingsContext);

  if (context === undefined) {
    throw new Error("useSettings must be used within a SettingsProvider");
  }

  return context;
};

interface SettingsProviderProps {
  children: ReactNode;
}

export const SettingsProvider: React.FC<SettingsProviderProps> = ({
  children,
}) => {
  const navigate = useNavigate();

  const { user } = useCurrentUser();

  const [settings, setSettings] = useState<SettingsWithCurrentGroup | null>(
    null,
  );

  const [settingsLoading, setSettingsLoading] = useState(true);

  useEffect(() => {
    getSettingsQuery(user.id).then(({ data }) => {
      setSettingsLoading(false);

      if (data) {
        setSettings(data);
      }
    });
  }, [navigate, user.id]);

  const refreshSettings = useCallback(async () => {
    const { data } = await supabase
      .from("suivi_settings")
      .select("*, current_group:suivi_groups(*)")
      .eq("user_id", user.id)
      .single();

    setSettings(data);
  }, [user.id]);

  const registerSettings = useCallback(
    async (settings: Partial<Tables<"suivi_settings">>) => {
      const { data } = await supabase
        .from("suivi_settings")
        .insert({ user_id: user.id, ...settings })
        .select("*, current_group:suivi_groups(*)")
        .single();

      setSettings(data);
    },
    [user.id],
  );

  const saveSettings = useCallback(
    async (updatedSettings: Partial<Tables<"suivi_settings">>) => {
      const { data } = await supabase
        .from("suivi_settings")
        .update(updatedSettings)
        .eq("user_id", user.id)
        .select("*, current_group:suivi_groups(*)")
        .single();

      setSettings(data);
    },
    [user.id],
  );

  if (settingsLoading) {
    return <LayoutLoading />;
  }

  return (
    <SettingsContext.Provider
      value={{
        settings,
        registerSettings,
        saveSettings,
        refreshSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};
