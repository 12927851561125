import clsx from "clsx";
import { useAtom } from "jotai";
import { FC, Fragment, useEffect, useRef, useState } from "react";
import { useStopwatch } from "react-timer-hook";
import { usePrevious } from "react-use";

import { showVirtualKeyboardAtom } from "~/atoms/showVirtualKeyboardAtom.ts";
import { TitleLabel } from "~/components/TitleLabel.tsx";
import { VirtualNumericKeyboard } from "~/components/VirtualNumericKeyboard.tsx";
import { Exercise } from "~/exercise.ts";
import { useScores } from "~/hooks/useScores.ts";
import { Cm1Operation } from "~/routes/cm1/templates.ts";
import {
  generateMathOperation,
  getTemplateFromName,
} from "~/routes/cm1/utils.ts";

interface Cm1Answer {
  at: Date;
  level: string;
  success: boolean;
  timing: number;
  operation: string;
}

export interface TrainingBaseViewProps {
  template: string;
}

export const TrainingBaseView: FC<TrainingBaseViewProps> = ({ template }) => {
  const { totalSeconds, reset: resetStopwatch } = useStopwatch({
    autoStart: true,
  });

  const { saveScore } = useScores();

  const [showVirtualKeyboard, setShowVirtualKeyboard] = useAtom(
    showVirtualKeyboardAtom,
  );

  const previousTemplate = usePrevious(template);

  const [currentElement, setCurrentElement] = useState<Cm1Operation>(
    generateMathOperation(getTemplateFromName(template).template),
  );
  const [answers, setAnswers] = useState<Cm1Answer[]>([]);

  const fakeBarRef = useRef<HTMLInputElement | null>(null);
  const [fakeBarText, setFakeBarText] = useState("");

  const handleOnClickNext = (shouldSaveAnswer = true) => {
    if (shouldSaveAnswer) {
      saveAnswer();
    }

    setCurrentElement(
      generateMathOperation(getTemplateFromName(template).template),
    );

    setFakeBarText("");

    if (!showVirtualKeyboard) {
      fakeBarRef.current?.focus();
    }
  };

  const handleFakeBarOnEnter = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.key === "Enter") {
      handleOnClickNext();
    }

    if (evt.key === "Escape") {
      setAnswers([]);
      handleOnClickNext(false);
      resetStopwatch();
    }
  };

  const saveAnswer = () => {
    const newAnswer: Cm1Answer = {
      at: new Date(),
      level: template,
      operation: `${currentElement.operation} = ${currentElement.result}`,
      success: currentElement.result.toString() === fakeBarText,
      timing: totalSeconds,
    };

    setAnswers([...answers, newAnswer]);

    saveScore(Exercise.CM1, newAnswer.level, {
      success: newAnswer.success,
      timing: newAnswer.timing,
    });

    resetStopwatch();
  };

  useEffect(() => {
    if (!previousTemplate || previousTemplate === template) {
      return;
    }

    setCurrentElement(
      generateMathOperation(getTemplateFromName(template).template),
    );
    setAnswers([]);
  }, [template, previousTemplate]);

  return (
    <Fragment>
      {currentElement && (
        <div className="grid grid-cols-1 gap-4">
          <div>
            <h2 className="mb-1 flex justify-between">
              <TitleLabel>Opération</TitleLabel>
              <span className="text-sm">
                {answers.filter((a) => a.success).length}/{answers.length}
              </span>
            </h2>
            <div className="overflow-hidden rounded-lg bg-slate-100 h-28">
              <div className="font-mono px-3 py-3 h-full flex justify-center items-center text-xl tabular-nums">
                {currentElement.operation}
              </div>
            </div>
            <div className="mt-2">
              <input
                ref={fakeBarRef}
                type="text"
                className={clsx(
                  "border-2 p-2 transition-colors duration-300 ease-in-out w-full rounded text-center text-2xl font-mono tracking-widest",
                  showVirtualKeyboard && "pointer-events-none",
                )}
                value={fakeBarText}
                onChange={(evt) => setFakeBarText(evt.currentTarget.value)}
                onKeyUp={handleFakeBarOnEnter}
                autoFocus={true}
              />
              <p className="text-right space-x-2">
                <button
                  className="text-xs underline hover:no-underline cursor-pointer mt-1 lg:hidden"
                  onClick={() => setShowVirtualKeyboard(!showVirtualKeyboard)}
                >
                  Afficher/masquer le clavier virtuel
                </button>
                <button
                  className="text-xs underline hover:no-underline cursor-pointer mt-1"
                  onClick={() => {
                    setAnswers([]);
                    handleOnClickNext(false);
                    resetStopwatch();
                  }}
                >
                  Réinitialiser
                </button>
              </p>
            </div>
            <ul className="mt-4 gap-1 flex flex-col-reverse mb-6">
              {answers.map((answer, idx) => (
                <li
                  key={`answer-${idx}`}
                  className={`rounded text-center py-1 font-mono text-sm ${
                    answer.success
                      ? "bg-green-100 text-green-800 "
                      : "bg-slate-100 text-slate-800"
                  }`}
                >
                  {answer.operation}{" "}
                  <span className="text-xs">({answer.timing} sec)</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      {showVirtualKeyboard && (
        <VirtualNumericKeyboard
          onChange={setFakeBarText}
          onEnterPress={handleOnClickNext}
        />
      )}
    </Fragment>
  );
};
