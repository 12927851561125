import { differenceInHours, format } from "date-fns";
import { RefreshCwIcon, TrashIcon } from "lucide-react";
import { FC, useState } from "react";

import { SyncStatus } from "~/components/SyncStatus.tsx";
import { Button } from "~/components/ui/button.tsx";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "~/components/ui/tooltip.tsx";
import { PilotestAutoSyncModal } from "~/routes/suivi/components/PilotestAutoSyncModal.tsx";
import { useAttempts } from "~/routes/suivi/providers/AttemptsProvider.tsx";
import { useImports } from "~/routes/suivi/providers/ImportsProvider.tsx";
import { usePilotestCredentials } from "~/routes/suivi/providers/PilotestCredentialsProvider.tsx";

export const TitleStatusPilotest: FC = () => {
  const { lastPilotestImportDate } = useImports();
  const { pilotestSyncing, retrieveResultsFromPilotest } = useAttempts();
  const { hasPilotestAutoSyncEnabled, disablePilotestAutoSync } =
    usePilotestCredentials();

  const [showPilotestSyncModal, setShowPilotestSyncModal] = useState(false);

  const lastPilotestImportDifference = differenceInHours(
    new Date(),
    lastPilotestImportDate ?? new Date(),
  );

  const handleDisablePilotestSync = () => {
    if (
      confirm(
        "Êtes-vous sur de vouloir désactiver l'import automatique Pilotest ? Vous devrez importer manuellement par fichier .csv vos résultats.",
      )
    ) {
      disablePilotestAutoSync();
    }
  };

  if (!lastPilotestImportDate) {
    return (
      <p className="text-sm text-slate-400 inline-flex items-center gap-2">
        <SyncStatus color={pilotestSyncing ? "blue" : undefined} />
        <span className="text-slate-300">
          {pilotestSyncing ? (
            <>
              Premier import <strong className="font-semibold">Pilotest</strong>{" "}
              en cours…
            </>
          ) : (
            <div className="space-x-2">
              Aucun import <strong className="font-semibold">Pilotest</strong>{" "}
              réalisé
              {!hasPilotestAutoSyncEnabled && (
                <Button
                  size="xs"
                  onClick={() => setShowPilotestSyncModal(true)}
                >
                  Activer l'import automatique
                </Button>
              )}
            </div>
          )}
        </span>
      </p>
    );
  }

  return (
    <>
      <div className="text-sm text-slate-400 inline-flex items-center gap-2 h-6">
        {lastPilotestImportDifference < 24 ? (
          <Tooltip delayDuration={0}>
            <TooltipTrigger>
              <SyncStatus color={pilotestSyncing ? "blue" : "green"} />
            </TooltipTrigger>
            <TooltipContent>
              <p>Dernier import Pilotest récent (- 24h)</p>
            </TooltipContent>
          </Tooltip>
        ) : (
          <Tooltip delayDuration={0}>
            <TooltipTrigger>
              <SyncStatus color={pilotestSyncing ? "blue" : "orange"} />
            </TooltipTrigger>
            <TooltipContent>
              <p>Dernier import Pilotest ancien (+ 24h)</p>
            </TooltipContent>
          </Tooltip>
        )}
        <p>
          Import {hasPilotestAutoSyncEnabled ? "" : "manuel"}{" "}
          <strong className="font-semibold">Pilotest</strong>{" "}
          {pilotestSyncing ? (
            "en cours…"
          ) : (
            <>du {format(lastPilotestImportDate, "dd/MM à HH:mm")}</>
          )}
        </p>
        {!hasPilotestAutoSyncEnabled && (
          <Button size="xs" onClick={() => setShowPilotestSyncModal(true)}>
            Activer l'import automatique
          </Button>
        )}
        {hasPilotestAutoSyncEnabled && !pilotestSyncing && (
          <div className="flex items-center gap-1.5">
            <Button
              size="xs"
              variant="ghost"
              onClick={retrieveResultsFromPilotest}
              disabled={pilotestSyncing}
            >
              <RefreshCwIcon className="w-3.5 h-3.5" />
            </Button>
            <Button
              size="xs"
              variant="ghost-destructive"
              onClick={handleDisablePilotestSync}
            >
              <TrashIcon className="w-3.5 h-3.5" />
            </Button>
          </div>
        )}
      </div>
      <PilotestAutoSyncModal
        open={showPilotestSyncModal}
        onDismiss={() => setShowPilotestSyncModal(false)}
      />
    </>
  );
};
