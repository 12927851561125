import { GroupIcon, Settings2Icon, SettingsIcon } from "lucide-react";
import { FC, useCallback, useEffect, useState } from "react";

import { ButtonHide } from "~/components/ButtonHide.tsx";
import { Button } from "~/components/ui/button.tsx";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "~/components/ui/dropdown-menu.tsx";
import { DimensionBlock } from "~/routes/suivi/components/Dimensions/DimensionBlock.tsx";
import { DimensionsAssigner } from "~/routes/suivi/components/Dimensions/DimensionsAssigner.tsx";
import { DimensionsFooter } from "~/routes/suivi/components/Dimensions/DimensionsFooter.tsx";
import { DimensionsInitializer } from "~/routes/suivi/components/Dimensions/DimensionsInitializer.tsx";
import { useSettings } from "~/routes/suivi/providers/SettingsProvider.tsx";
import {
  DimensionWithExercises,
  getDimensionWithExercisesQuery,
} from "~/routes/suivi/queries/dimensionsWithExercisesQuery.ts";
import { supabase } from "~/supabase.ts";
import { Enums, Tables } from "~/supabase.types.ts";

export const Dimensions: FC = () => {
  const { settings } = useSettings();

  const [dimension, setDimension] = useState<DimensionWithExercises | null>(
    null,
  );

  const refreshDimensions = useCallback(() => {
    if (!settings?.current_group_id) {
      return;
    }

    getDimensionWithExercisesQuery(settings.current_group_id).then(({ data }) =>
      setDimension(data ?? null),
    );
  }, [settings?.current_group_id]);

  useEffect(() => {
    refreshDimensions();
  }, [refreshDimensions]);

  const handleOnUpdateDimension = (
    updates: Partial<Tables<"suivi_dimensions">>,
  ) => {
    if (!dimension) {
      return;
    }

    return supabase
      .from("suivi_dimensions")
      .update(updates)
      .eq("id", dimension.id)
      .then(refreshDimensions);
  };

  const handleOnDeleteDimension = () => {
    if (!dimension) {
      return;
    }

    return supabase
      .from("suivi_dimensions")
      .delete()
      .eq("id", dimension.id)
      .then(refreshDimensions);
  };

  if (!settings?.current_group) {
    return null;
  }

  return (
    <div className="hidden sm:block">
      <div className="border rounded">
        <div className="flex items-center justify-between border-b px-3 py-1.5 bg-slate-100">
          <p className="font-medium text-2xl inline-flex items-center gap-2.5 pl-1 h-9">
            <GroupIcon className="text-slate-600" />
            <span>Dimensions</span>
          </p>
          <div className="hidden sm:flex sm:items-center sm:gap-2">
            {dimension && dimension.state !== "draft" && (
              <>
                <ButtonHide
                  hidden={dimension.hidden}
                  onToggle={(newValue) =>
                    handleOnUpdateDimension({ hidden: newValue })
                  }
                />
                {!dimension.hidden && (
                  <>
                    <Button
                      variant="outline"
                      onClick={() =>
                        handleOnUpdateDimension({
                          state:
                            dimension.state === "editing" ? "valid" : "editing",
                        })
                      }
                    >
                      <Settings2Icon className="w-4 h-4" />
                      <span className="ml-2">Editer</span>
                    </Button>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button variant="outline">
                          <SettingsIcon className="w-4 h-4" />
                          <span className="ml-2">Paramètres</span>
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent className="w-56">
                        <DropdownMenuLabel>Calcul</DropdownMenuLabel>
                        <DropdownMenuSeparator />
                        <DropdownMenuRadioGroup
                          value={dimension.compute ?? "average_5_attempts"}
                          onValueChange={(compute) =>
                            handleOnUpdateDimension({
                              compute: compute as Enums<"dimension_compute">,
                            })
                          }
                        >
                          <DropdownMenuRadioItem
                            value="last_attempt"
                            onSelect={(evt) => evt.preventDefault()}
                          >
                            Derniers résultats
                          </DropdownMenuRadioItem>
                          <DropdownMenuRadioItem
                            value="average_3_attempts"
                            onSelect={(evt) => evt.preventDefault()}
                          >
                            Moy. 3 derniers résultats
                          </DropdownMenuRadioItem>
                          <DropdownMenuRadioItem
                            value="average_5_attempts"
                            onSelect={(evt) => evt.preventDefault()}
                          >
                            Moy. 5 derniers résultats
                          </DropdownMenuRadioItem>
                          <DropdownMenuRadioItem
                            value="average_10_attempts"
                            onSelect={(evt) => evt.preventDefault()}
                          >
                            Moy. 10 derniers résultats
                          </DropdownMenuRadioItem>
                        </DropdownMenuRadioGroup>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <div>
          {!dimension ? (
            <DimensionsInitializer
              group={settings.current_group}
              onSubmit={refreshDimensions}
            />
          ) : dimension.state === "draft" ? (
            <DimensionsAssigner
              dimension={dimension}
              onSubmit={() => handleOnUpdateDimension({ state: "valid" })}
              onAbort={handleOnDeleteDimension}
            />
          ) : dimension.state === "editing" ? (
            <DimensionsAssigner
              dimension={dimension}
              onSubmit={() => handleOnUpdateDimension({ state: "valid" })}
              onAbort={() => handleOnUpdateDimension({ state: "valid" })}
              onDelete={handleOnDeleteDimension}
            />
          ) : (
            <DimensionBlock dimension={dimension} />
          )}
        </div>
      </div>
      {dimension && dimension.state === "valid" && (
        <DimensionsFooter dimension={dimension} />
      )}
    </div>
  );
};
