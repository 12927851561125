import clsx from "clsx";
import { FC } from "react";

import IconEpl from "~/routes/suivi/imgs/epl-icon.png";
import IconPilotest from "~/routes/suivi/imgs/pt-icon.png";
import { getExerciseNameAndSource } from "~/routes/suivi/utils/getExerciseNameAndSource.ts";
import { popupWindow } from "~/utils/popupWindow.tsx";

export interface ExerciseWithIconProps {
  exerciseName: string;
  customName?: string;
  withLink?: boolean;
  size?: "md" | "lg";
}

export const ExerciseWithIcon: FC<ExerciseWithIconProps> = ({
  exerciseName,
  customName,
  withLink = true,
  size = "md",
}) => {
  const { name, source, link } = getExerciseNameAndSource(exerciseName);

  return (
    <>
      <img
        alt="Icon"
        src={source === "EPL" ? IconEpl : IconPilotest}
        className={clsx(size === "lg" ? "w-5 h-5" : "w-4 h-4")}
      />
      {withLink && link ? (
        <a
          href={link}
          target="_blank"
          className="truncate cursor-pointer underline hover:no-underline"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();

            return popupWindow(link, {
              hideCurrentPage: true,
              w: 1024,
              centerVertically: false,
            });
          }}
        >
          {customName ?? name}
        </a>
      ) : (
        <span className="truncate" title={customName ?? name}>
          {customName ?? name}
        </span>
      )}
    </>
  );
};
