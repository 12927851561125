import { LightbulbIcon } from "lucide-react";
import { FC, FormEvent, useState } from "react";

import { Button } from "~/components/ui/button.tsx";
import { Input } from "~/components/ui/input.tsx";

interface PasswordProps {
  onUnlock: () => void;
}

export const Password: FC<PasswordProps> = ({ onUnlock }) => {
  const [code, setCode] = useState("");

  const handleOnSubmit = async (evt: FormEvent) => {
    evt.preventDefault();

    if (code !== "Go!Psy02022") {
      alert("Le mot de passe n'est pas reconnu !");
      return setCode("");
    }

    onUnlock();
  };

  return (
    <form
      onSubmit={handleOnSubmit}
      className="max-w-xl mx-auto flex flex-col justify-center"
    >
      <p className="mx-auto">
        <LightbulbIcon className="block w-20 h-20 text-blue-600" />
      </p>
      <p className="mt-6">
        À l'initiative du{" "}
        <a
          className="underline hover:no-underline font-medium"
          href="https://discord.com/channels/1031678989384827010/1148725886946902157"
          target="_blank"
        >
          Discord Cadets Air France
        </a>
        , cet outil rassemble des questions de Culture Générale proposées par
        les membres du Discord au fil des différentes sélections Cadets Air
        France et Transavia.
      </p>
      <p className="mt-4">
        Pour accéder aux questions, merci de{" "}
        <span className="font-semibold">
          suivre les étapes indiquées dans le salon{" "}
          <a
            className="underline hover:no-underline bg-blue-600 text-white px-2 py-0.5 rounded"
            href="https://discord.com/channels/1031678989384827010/1148725886946902157"
            target="_blank"
          >
            #quizlet-comment-y-acceder
          </a>
        </span>
        . Une fois les étapes complétées vous recevrez le mot de passe à saisir
        ci-dessous.
      </p>
      <div className="w-full mt-6">
        <label className="text-sm font-medium text-slate-500 mb-1.5 block">
          Mot de passe :
        </label>
        <Input
          className="w-full font-mono font-medium text-center text-xl tracking-wider"
          value={code}
          onChange={(e) => setCode(e.currentTarget.value)}
          required={true}
          autoFocus={true}
        />
      </div>

      <p className="mt-3 w-full">
        <Button className="w-full" type="submit" onClick={() => {}}>
          Accéder au Quizlet
        </Button>
      </p>
    </form>
  );
};
