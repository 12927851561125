import { RabbitIcon, SnailIcon } from "lucide-react";
import { FC } from "react";

import { Slider } from "~/components/ui/slider.tsx";

interface SpeedSliderProps {
  value: number;
  onChange: (newSpeed: number) => void;
  min?: number;
  max?: number;
  step?: number;
}

export const SpeedSlider: FC<SpeedSliderProps> = ({
  value,
  onChange,
  min = 1,
  max = 3,
  step = 0.5,
}) => (
  <div>
    <div className="w-56 flex items-center justify-center gap-3 mx-auto mt-6">
      <p className="shrink-0 text-xs text-slate-400">
        <RabbitIcon />
      </p>
      <Slider
        min={min}
        max={max}
        step={step}
        value={[value]}
        onValueChange={([newSpeed]) => onChange(newSpeed)}
      />
      <p className="shrink-0 text-xs text-slate-400">
        <SnailIcon />
      </p>
    </div>
    <p className="text-center text-sm mt-1 text-slate-800">{value} sec</p>
  </div>
);
