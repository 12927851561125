import {
  BoxIcon,
  BrainIcon,
  JoystickIcon,
  MessageSquareIcon,
  ScanEyeIcon,
  SigmaIcon,
} from "lucide-react";

interface DimensionIconProps {
  icon: string | null;
}

export const DimensionIcon = ({ icon }: DimensionIconProps) => {
  const Icon =
    icon === "message-square"
      ? MessageSquareIcon
      : icon === "scan-eye"
        ? ScanEyeIcon
        : icon === "box"
          ? BoxIcon
          : icon === "joystick"
            ? JoystickIcon
            : icon === "brain"
              ? BrainIcon
              : icon === "sigma"
                ? SigmaIcon
                : null;

  if (!icon || Icon === null) {
    return <div className="w-4 h-4" />;
  }

  return <Icon className="w-4 h-4 text-slate-500" />;
};
