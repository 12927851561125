import clsx from "clsx";
import { PlusIcon, SquareStackIcon } from "lucide-react";
import { FC, useEffect, useState } from "react";

import { Button } from "~/components/ui/button.tsx";
import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { ExerciseWithIcon } from "~/routes/suivi/components/ExerciseWithIcon.tsx";
import { useAttempts } from "~/routes/suivi/providers/AttemptsProvider.tsx";
import { useGroups } from "~/routes/suivi/providers/GroupsProvider.tsx";
import { getAllTakenExerciseNames } from "~/routes/suivi/utils/getAllTakenExerciseNames.ts";
import { supabase } from "~/supabase.ts";

export const GroupsBox: FC = () => {
  const { user } = useCurrentUser();
  const { attempts } = useAttempts();
  const { groups, refreshGroups } = useGroups();

  const [allExerciseNames, setAllExerciseNames] = useState<string[]>([]);

  useEffect(() => {
    setAllExerciseNames(getAllTakenExerciseNames(attempts));
  }, [attempts]);

  const handleOnCreateGroup = () => {
    const groupName = prompt("Nom du groupe à ajouter (ex: PSY0) ?");

    if (!groupName) {
      return;
    }

    supabase
      .from("suivi_groups")
      .insert({ user_id: user.id, name: groupName, exercises: [] })
      .then(refreshGroups);
  };

  const handleOnCopyGroup = (
    exercises: string[] = [],
    customOrder: boolean | null,
  ) => {
    const groupName = prompt("Quel est le nom de la copie ?");

    if (!groupName) {
      return;
    }

    supabase
      .from("suivi_groups")
      .insert({
        user_id: user.id,
        name: groupName,
        exercises,
        use_custom_order: customOrder,
      })
      .then(refreshGroups);
  };

  const handleOnRenameGroup = (groupId: string) => {
    const groupName = prompt("Quel est le nouveau nom du group ?");

    if (!groupName) {
      return;
    }

    supabase
      .from("suivi_groups")
      .update({ name: groupName })
      .eq("id", groupId)
      .then(refreshGroups);
  };

  const handleOnUpdateGroup = (
    groupId: string,
    exercises: string[] = [],
    withCustomOrder: boolean | null = null,
  ) =>
    supabase
      .from("suivi_groups")
      .update({ exercises, use_custom_order: withCustomOrder })
      .eq("id", groupId)
      .then(refreshGroups);

  const handleOnDeleteGroup = (groupId: string) =>
    supabase
      .from("suivi_groups")
      .delete()
      .eq("id", groupId)
      .then(refreshGroups);

  if (groups?.length === 0) {
    return (
      <div className="h-[calc(100vh_-_112px)] flex flex-col items-center justify-center">
        <p>
          <SquareStackIcon className="text-blue-600 h-12 w-12" />
        </p>
        <p className="mt-3 max-w-lg text-center">
          <strong className="font-medium">
            Utilisez les groupes pour classer les exercices selon vos
            préférences (par exemple, PSY0 et PSY1).
          </strong>
        </p>
        <p className="mt-4">
          <Button onClick={handleOnCreateGroup}>
            <PlusIcon className="h-4 w-4 mr-2" />
            Créer un groupe
          </Button>
        </p>
      </div>
    );
  }

  return (
    <div>
      <div className="flex flex-col gap-4">
        {groups?.map((g) => (
          <div key={`group-${g.id}`} className="border rounded">
            <div className="bg-slate-100 px-3 py-1.5 flex justify-between items-center">
              <h3 className="font-semibold text-lg">
                {g.name}{" "}
                <span className="font-light">
                  • {g.exercises.length} exercice(s)
                </span>
              </h3>
              <div className="flex items-center space-x-2.5">
                <button
                  className="text-xs underline hover:no-underline text-red-500"
                  onClick={() => {
                    if (
                      confirm("Souhaitez-vous vraiment supprimer ce groupe ?")
                    ) {
                      handleOnDeleteGroup(g.id);
                    }
                  }}
                  data-umami-event="suivi:deleteGroup"
                >
                  Supprimer
                </button>
                <button
                  className="text-xs underline hover:no-underline text-slate-500"
                  onClick={() =>
                    handleOnCopyGroup(g.exercises, g.use_custom_order)
                  }
                  data-umami-event="suivi:copyGroup"
                >
                  Copier
                </button>
                <button
                  className="text-xs underline hover:no-underline text-slate-500"
                  onClick={() => handleOnRenameGroup(g.id)}
                  data-umami-event="suivi:renameGroup"
                >
                  Renommer
                </button>
                {g.use_custom_order && (
                  <button
                    className="text-xs underline hover:no-underline text-slate-500"
                    onClick={() =>
                      handleOnUpdateGroup(g.id, g.exercises.sort())
                    }
                    data-umami-event="suivi:resetSort"
                  >
                    Réinitialiser l'ordre
                  </button>
                )}
              </div>
            </div>
            <div className="gap-3 columns-3 px-2 py-2 space-y-0.5">
              {allExerciseNames.map((e) => (
                <p
                  key={`group-${g.id}-${e}`}
                  onClick={() =>
                    handleOnUpdateGroup(
                      g.id,
                      g.exercises.includes(e)
                        ? g.exercises.filter((ge) => ge !== e)
                        : [...g.exercises, e],
                      g.use_custom_order,
                    )
                  }
                  className={clsx(
                    "text-xs flex items-center gap-1.5 cursor-pointer truncate overflow-hidden px-1.5 py-1",
                    !g.exercises.includes(e)
                      ? "opacity-50"
                      : "bg-blue-100 rounded",
                  )}
                >
                  <ExerciseWithIcon exerciseName={e} withLink={false} />
                </p>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="mt-6 mb-8">
        <Button onClick={handleOnCreateGroup}>
          <PlusIcon className="h-4 w-4 mr-2" />
          Ajouter un groupe
        </Button>
      </div>
    </div>
  );
};
