import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
  TransitionChild,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { clsx } from "clsx";
import { FC, ReactNode, useEffect } from "react";

export interface DrawerProps {
  open: boolean;
  onDismiss: () => void;
  title: ReactNode;
  children: ReactNode;
  blurBackground?: boolean;
}

export const Drawer: FC<DrawerProps> = ({
  open,
  onDismiss,
  title,
  children,
  blurBackground,
}) => {
  useEffect(() => {
    const closeOnEscapeKey = (e: KeyboardEvent) =>
      e.key === "Escape" ? onDismiss() : null;

    document.body.addEventListener("keydown", closeOnEscapeKey);

    return () => {
      document.body.removeEventListener("keydown", closeOnEscapeKey);
    };
  }, [onDismiss]);

  return (
    <Dialog open={open} onClose={onDismiss} className="relative z-10">
      <DialogBackdrop
        transition
        className={clsx(
          "fixed inset-0 bg-slate-200 transition-opacity duration-200 ease-in-out data-[closed]:opacity-0",
          blurBackground ? "bg-opacity-90" : "bg-opacity-40",
        )}
      />
      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel
              transition
              className="pointer-events-auto relative w-screen max-w-3xl transform transition duration-200 ease-in-out data-[closed]:translate-x-full sm:duration-200"
            >
              <TransitionChild>
                <div className="absolute left-0 top-0 -ml-8 mt-2 flex pr-2 pt-4 duration-200 ease-in-out data-[closed]:opacity-0 sm:-ml-10 sm:pr-4">
                  <button
                    type="button"
                    onClick={onDismiss}
                    className="relative rounded-md text-black hover:text-slate-500 focus:outline-none focus:ring-2 focus:ring-white"
                  >
                    <span className="absolute -inset-2.5" />
                    <span className="sr-only">Fermer</span>
                    <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                  </button>
                </div>
              </TransitionChild>
              <div className="flex h-[calc(100%-15px)] flex-col overflow-y-scroll border bg-white mt-2  rounded-lg mr-2 no-scrollbar">
                <div className="bg-slate-100 px-4 py-3 sm:px-6 border-b">
                  <div className="flex items-center justify-between">
                    <DialogTitle className="font-medium text-lg">
                      {title}
                    </DialogTitle>
                  </div>
                </div>
                <div className="relative mt-6 flex-1 px-4 sm:px-6">
                  {children}
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
