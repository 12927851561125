import { addSeconds } from "date-fns";
import { FC } from "react";
import { useTimer } from "react-timer-hook";

import { Button, ButtonProps } from "~/components/ui/button.tsx";

interface ButtonCountdownProps extends ButtonProps {
  duration: number;
  onExpire: () => void;
}

export const ButtonCountdown: FC<ButtonCountdownProps> = ({
  children,
  duration,
  onExpire,
  onClick,
  className,
  type,
}) => {
  const { totalSeconds } = useTimer({
    autoStart: duration !== -1,
    expiryTimestamp: addSeconds(new Date(), duration),
    onExpire,
  });

  if (duration === -1) {
    return (
      <Button onClick={onClick} className={className} type={type}>
        <span>{children}</span>
      </Button>
    );
  }

  return (
    <Button onClick={onClick} className={className} type={type}>
      <span>{children}</span>
      <span className="text-xs ml-1.5 text-slate-200 font-light tabular-nums">
        ({totalSeconds})
      </span>
    </Button>
  );
};
