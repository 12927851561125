import { ZoomInIcon } from "lucide-react";
import { FC } from "react";

interface ExercisesResultsDetailsProps {
  onShowDetails: () => void;
}

export const ExercisesResultsDetails: FC<ExercisesResultsDetailsProps> = ({
  onShowDetails,
}) => (
  <button
    className="text-slate-300 hover:text-slate-500 cursor-pointer"
    onClick={onShowDetails}
    data-umami-event="suivi:seeDetails"
  >
    <ZoomInIcon className="hidden sm:group-hover:block w-4 h-4" />
  </button>
);
