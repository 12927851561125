import { FC, useState } from "react";

import { Button } from "~/components/ui/button.tsx";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "~/components/ui/dialog.tsx";
import { Input } from "~/components/ui/input.tsx";
import { Label } from "~/components/ui/label.tsx";
import { useAttempts } from "~/routes/suivi/providers/AttemptsProvider.tsx";
import { usePilotestCredentials } from "~/routes/suivi/providers/PilotestCredentialsProvider.tsx";

interface PilotestAutoSyncModalProps {
  open: boolean;
  onDismiss: () => void;
}

export const PilotestAutoSyncModal: FC<PilotestAutoSyncModalProps> = ({
  open,
  onDismiss,
}) => {
  const { checkPilotestConnectivity } = useAttempts();
  const { enablePilotestAutoSync } = usePilotestCredentials();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [statusCheck, setStatusCheck] = useState<
    "idle" | "checking" | "success"
  >("idle");
  const [error, setError] = useState<string | null>(null);

  const isDisabled = !email || !password || statusCheck !== "idle";

  const handleOnCheckConnexion = async () => {
    if (!email || !password) {
      return alert("Identifiants manquants !");
    }

    setStatusCheck("checking");
    setError(null);

    checkPilotestConnectivity(email, password)
      .then(() => setStatusCheck("success"))
      .catch((err) => {
        setError(err);
        setStatusCheck("idle");
      });
  };

  const handleOnSubmit = () => {
    enablePilotestAutoSync(email, password);
    onDismiss();
  };

  return (
    <Dialog open={open} onOpenChange={onDismiss}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="inline-flex items-center gap-2">
            Activer l'import automatique avec Pilotest
          </DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-4">
          <p className="text-sm">
            En l'absence d'un service de récupération fourni par Pilotest, vous
            pouvez enregistrer vos identifiants Pilotest pour que KD Tools
            récupère vos derniers résultats à chaque visite.
          </p>
          <p className="text-sm">
            <strong className="font-semibold">
              Pour plus de sécurité, vos identifiants Pilotest seront stockés de
              façon sécurisée dans votre navigateur.{" "}
              <span className="font-normal">
                Si vous utilisez KD Tools depuis différents appareils, vous
                devrez les saisir à nouveau.
              </span>
            </strong>
          </p>
        </div>
        <div className="mt-1 flex flex-col gap-3">
          <div className="flex flex-col gap-2">
            <Label htmlFor="pt-usr">Adresse e-mail Pilotest :</Label>
            <Input
              name="pt-usr"
              autoComplete="none"
              value={email}
              onChange={(evt) => setEmail(evt.currentTarget.value)}
              disabled={statusCheck === "success"}
            />
          </div>
          <div className="flex flex-col gap-2">
            <Label htmlFor="pt-pwd">Mot de passe Pilotest :</Label>
            <Input
              name="pt-pwd"
              type="password"
              autoComplete="none"
              value={password}
              onChange={(evt) => setPassword(evt.currentTarget.value)}
              disabled={statusCheck === "success"}
            />
          </div>
        </div>
        {error && (
          <div className="-mb-2 text-center font-semibold text-red-500">
            Une erreur est survenue ({error}).
          </div>
        )}
        {statusCheck === "success" && (
          <div className="-mb-2 text-center font-semibold text-green-500">
            Connexion réussie ! 🎉
          </div>
        )}
        <div>
          {statusCheck === "success" ? (
            <Button
              type="button"
              onClick={handleOnSubmit}
              className="w-full"
              variant="success"
            >
              Enregistrer mes identifiants Pilotest
            </Button>
          ) : (
            <Button
              type="button"
              onClick={handleOnCheckConnexion}
              disabled={isDisabled}
              className="w-full"
            >
              {statusCheck === "checking"
                ? "Connexion en cours…"
                : "Tester la connexion Pilotest"}
            </Button>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
