import { format } from "date-fns";
import { FC } from "react";

import { useSettings } from "~/routes/suivi/providers/SettingsProvider.tsx";
import { DimensionWithExercises } from "~/routes/suivi/queries/dimensionsWithExercisesQuery.ts";

interface DimensionsFooterProps {
  dimension: DimensionWithExercises;
}

export const DimensionsFooter: FC<DimensionsFooterProps> = ({ dimension }) => {
  const { settings } = useSettings();

  if (dimension.hidden) {
    return null;
  }

  return (
    <div className="text-xs mt-2 text-slate-400 leading-5">
      {!dimension.compute ||
        (dimension.compute.includes("average") ? (
          <p>
            La moyenne par dimension est calculée à partir des{" "}
            <strong className="font-medium">
              {dimension.compute.includes("3")
                ? 3
                : dimension.compute.includes("5")
                  ? 5
                  : 10}{" "}
              derniers essais
            </strong>{" "}
            de chaque exercice de la dimension{" "}
            <strong className="font-medium">
              {settings?.display_from_date
                ? `depuis le ${format(settings.display_from_date, "dd/MM/y")}`
                : ""}
            </strong>
            .
          </p>
        ) : (
          <p>
            La moyenne par dimension est calculée à partir du{" "}
            <strong className="font-medium">dernier essai</strong> de chaque
            exercice de la dimension{" "}
            <strong className="font-medium underline">
              {settings?.display_from_date
                ? `depuis le ${format(settings.display_from_date, "dd/MM/y")}`
                : ""}
            </strong>
            .
          </p>
        ))}
    </div>
  );
};
