import clsx from "clsx";
import { FC } from "react";

interface SyncStatusProps {
  color?: "green" | "orange" | "blue";
}

export const SyncStatus: FC<SyncStatusProps> = ({ color }) => {
  const primaryClassName =
    color === "green"
      ? "bg-green-500"
      : color === "orange"
        ? "bg-orange-500"
        : color === "blue"
          ? "bg-sky-500"
          : "bg-slate-300";

  const secondaryClassName =
    color === "green"
      ? "bg-green-300"
      : color === "orange"
        ? "bg-orange-300"
        : color === "blue"
          ? "bg-sky-300"
          : "bg-slate-200";

  return (
    <span className="relative flex w-2 h-2">
      <span
        className={clsx(
          "absolute inline-flex h-full w-full rounded-full opacity-75",
          secondaryClassName,
          color !== "green" && "animate-ping",
        )}
      />
      <span
        className={clsx(
          "relative inline-flex rounded-full w-2 h-2",
          primaryClassName,
        )}
      />
    </span>
  );
};
