export enum Role {
  Admin = "admin",
  AdminQuizlet = "admin-quizlet",
  Angles = "angles",
  CM1 = "cm1",
  CM2 = "cm2",
  Tangram = "tangram",
  Memory = "memory",
  MemoryCalculs = "memory-calculs",
}
