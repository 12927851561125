import { QueryData } from "@supabase/supabase-js";

import { supabase } from "~/supabase.ts";

export const getDimensionWithExercisesQuery = (groupId: string) =>
  supabase
    .from("suivi_dimensions")
    .select(
      "id, state, compute, from_date, hidden, dimensions:suivi_dimensions_dimensions(id, name, icon, exercises:suivi_dimensions_exercises(id, name, weight))",
    )
    .eq("group_id", groupId)
    .maybeSingle();

export type DimensionWithExercises = QueryData<
  ReturnType<typeof getDimensionWithExercisesQuery>
>;

export type DimensionWithExercisesExercises =
  DimensionWithExercises["dimensions"][0]["exercises"];
