import clsx from "clsx";
import { TrophyIcon } from "lucide-react";
import * as React from "react";
import { Link, useLocation } from "react-router-dom";

import { NavGroup } from "~/components/ui/nav-group.tsx";
import { NavSecondary } from "~/components/ui/nav-secondary.tsx";
import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "~/components/ui/sidebar";
import { psy0Routes, psy1Routes } from "~/router.tsx";

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  const location = useLocation();

  return (
    <Sidebar variant="inset" {...props}>
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <Link to="/" className="flex justify-center mt-2">
              <img src="/ico.png" className="w-16 drop-shadow" />
            </Link>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup>
          <SidebarMenu>
            <SidebarMenuItem
              className={clsx(
                location.pathname === "/suivi/stats" && "text-blue-600",
              )}
            >
              <SidebarMenuButton
                isActive={location.pathname.includes("/suivi")}
                asChild
              >
                <Link to="/suivi" className="font-medium text-[16px]">
                  <p className="inline-flex items-center gap-2">
                    <TrophyIcon className="w-5 h-5" />
                    <span>Suivi</span>
                  </p>
                </Link>
              </SidebarMenuButton>
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarGroup>
        <NavGroup name="PSY 0" routes={psy0Routes} />
        <NavGroup name="PSY 1" routes={psy1Routes} />
        <NavSecondary className="mt-auto" />
      </SidebarContent>
    </Sidebar>
  );
}
