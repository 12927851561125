import { SaveIcon, Trash2Icon, Undo2Icon } from "lucide-react";
import { FC } from "react";
import { useImmer } from "use-immer";

import { ExercisesSelect } from "~/components/ExercisesSelect.tsx";
import { Button } from "~/components/ui/button.tsx";
import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { DimensionIcon } from "~/routes/suivi/components/Dimensions/DimensionIcon.tsx";
import { useRenamings } from "~/routes/suivi/providers/RenamingsProvider.tsx";
import { useSettings } from "~/routes/suivi/providers/SettingsProvider.tsx";
import { DimensionWithExercises } from "~/routes/suivi/queries/dimensionsWithExercisesQuery.ts";
import { supabase } from "~/supabase.ts";

interface DimensionsAssignerProps {
  dimension: DimensionWithExercises;
  onSubmit: () => void;
  onAbort: () => void;
  onDelete?: () => void;
}

export const DimensionsAssigner: FC<DimensionsAssignerProps> = ({
  dimension,
  onSubmit,
  onAbort,
  onDelete,
}) => {
  const { user } = useCurrentUser();
  const { settings } = useSettings();
  const { renamingsList } = useRenamings();

  const [dimensionDraft, setDimensionDraft] =
    useImmer<DimensionWithExercises>(dimension);

  const handleOnDeleteDimension = () => {
    if (
      confirm(
        "Souhaitez-vous vraiment supprimer les dimensions pour ce groupe ?",
      )
    ) {
      onDelete?.();
    }
  };

  const handleOnSaveDimension = () => {
    Promise.all(
      dimensionDraft.dimensions.map(async (dimension) =>
        supabase.from("suivi_dimensions_exercises").insert(
          dimension.exercises.map((ex) => ({
            user_id: user.id,
            dimension_id: dimension.id,
            name: ex.name,
            weight: 1,
          })),
        ),
      ) ?? [],
    ).then(onSubmit);
  };

  return (
    <div className="w-full px-8 py-6">
      <div className="grid grid-cols-2 gap-6">
        {dimensionDraft.dimensions.map((dim) => (
          <div key={dim.id}>
            <h4 className="text-lg font-medium inline-flex items-center gap-2">
              <DimensionIcon icon={dim.icon} />
              <span> {dim.name}</span>
            </h4>
            <div className="mt-1">
              <ExercisesSelect
                customOptions={
                  settings?.current_group?.exercises.map((ex) => ({
                    value: ex,
                    customName: renamingsList[ex],
                  })) ?? []
                }
                value={dim.exercises.map((ex) => ({
                  value: ex.name,
                  customName: renamingsList[ex.name],
                }))}
                onChange={(updatedValues) =>
                  setDimensionDraft((draft) => {
                    const dimensionToUpdate = draft?.dimensions.find(
                      (d) => d.id === dim.id,
                    );

                    if (!dimensionToUpdate) {
                      return;
                    }

                    dimensionToUpdate.exercises = updatedValues.map((uv) => ({
                      id: "DRAFT",
                      name: uv.value,
                      weight: 1,
                    }));
                  })
                }
              />
            </div>
            {/*<p className="text-xs text-slate-500 mt-1.5">*/}
            {/*  Pour les PSY1, les exercices repris dans cette dimension sont : X,*/}
            {/*  Y et Z.*/}
            {/*</p>*/}
          </div>
        ))}
      </div>
      <div className="flex justify-between mt-6 gap-4">
        <div>
          {onDelete && (
            <Button
              variant="ghost-destructive"
              onClick={handleOnDeleteDimension}
            >
              <Trash2Icon className="w-4 h-4" />
              <span className="ml-2">Supprimer</span>
            </Button>
          )}
        </div>
        <div className="space-x-4">
          <Button variant="outline" onClick={onAbort}>
            <Undo2Icon className="w-4 h-4" />
            <span className="ml-2">Annuler</span>
          </Button>
          <Button onClick={handleOnSaveDimension}>
            <SaveIcon className="w-4 h-4" />
            <span className="ml-2">Enregistrer</span>
          </Button>
        </div>
      </div>
    </div>
  );
};
