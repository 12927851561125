import { EyeIcon, EyeOffIcon } from "lucide-react";
import { FC } from "react";

import { Button } from "~/components/ui/button.tsx";

interface ButtonHideProps {
  hidden?: boolean | null;
  onToggle: (newValue: boolean) => void;
}

export const ButtonHide: FC<ButtonHideProps> = ({ hidden, onToggle }) => {
  if (hidden) {
    return (
      <Button variant="outline" onClick={() => onToggle(false)}>
        <EyeIcon className="w-4 h-4" />
        <span className="ml-2">Afficher</span>
      </Button>
    );
  }

  return (
    <Button variant="outline" onClick={() => onToggle(true)}>
      <EyeOffIcon className="w-4 h-4" />
      <span className="ml-2">Masquer</span>
    </Button>
  );
};
