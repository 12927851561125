import clsx from "clsx";
import { random } from "lodash";
import { useState } from "react";
import { useStopwatch } from "react-timer-hook";
import { useSet } from "react-use";

import { ToggleShort } from "~/components/ToggleShort.tsx";
import { Button } from "~/components/ui/button.tsx";
import { Exercise } from "~/exercise.ts";
import { useScores } from "~/hooks/useScores.ts";
import { Angle } from "~/routes/angles/Angle.tsx";
import { Clock } from "~/routes/angles/Clock.tsx";
import { ComplementaryAnglesHint } from "~/routes/angles/ComplementaryAnglesHint.tsx";
import { getPropositions, getRandomAngle } from "~/routes/angles/utils.ts";

export const GridView = () => {
  const { saveScore } = useScores();
  const {
    totalSeconds,
    pause,
    reset: resetStopwatch,
  } = useStopwatch({
    autoStart: true,
  });

  const [angle, setAngle] = useState(getRandomAngle());
  const [rotation, setRotation] = useState(random(0, 359));
  const [propositions, setPropositions] = useState(getPropositions(angle));
  const [
    selectedPropositions,
    {
      has: hasSelectedPropositions,
      toggle: toggleProposition,
      clear: clearSelectedPropositions,
    },
  ] = useSet(new Set<number>([]));
  const [showAnswer, setShowAnswer] = useState(false);
  const [showAngle, setShowAngle] = useState(false);
  const [answers, setAnswers] = useState<boolean[]>([]);

  const handleNext = () => {
    const results: boolean[] = [];
    propositions.forEach((p, idx) => {
      results.push(
        (p.isCorrect && hasSelectedPropositions(idx)) ||
          (!p.isCorrect && !hasSelectedPropositions(idx)),
      );
    });
    setAnswers([...answers, results.every(Boolean)]);

    saveScore(Exercise.Angles, "default", {
      success: results.every(Boolean),
      timing: totalSeconds,
    });

    reset();
  };

  const reset = () => {
    const newAngle = getRandomAngle();

    setAngle(newAngle);
    setRotation(random(0, 359));
    setPropositions(getPropositions(newAngle));
    setShowAnswer(false);
    clearSelectedPropositions();
    resetStopwatch();
  };

  return (
    <div className="flex flex-col sm:flex-row gap-4 sm:gap-8">
      <div className="sm:w-9/12">
        <div className="grid grid-cols-3 gap-2">
          <Angle
            angle={angle}
            rotation={rotation}
            className="bg-slate-100 border border-black rounded"
          />
          {propositions.map((p, idx) => (
            <Clock
              key={`clock-${idx}`}
              angle={p.angle}
              direction={p.direction}
              selected={hasSelectedPropositions(idx)}
              onClick={() => {
                if (
                  showAnswer ||
                  (selectedPropositions.size === 4 &&
                    !hasSelectedPropositions(idx))
                ) {
                  return;
                }

                toggleProposition(idx);
              }}
              className={clsx(
                showAnswer && p.isCorrect && "bg-green-100",
                showAnswer &&
                  !p.isCorrect &&
                  hasSelectedPropositions(idx) &&
                  "bg-red-100",
              )}
            />
          ))}
        </div>
        <div className="mt-4 text-center">
          {showAnswer ? (
            <Button onClick={handleNext} className="w-full">
              Suivant
            </Button>
          ) : (
            <Button
              className="w-full"
              onClick={() => {
                pause();
                setShowAnswer(true);
              }}
            >
              Correction
            </Button>
          )}
        </div>
      </div>
      <div className="sm:w-3/12">
        <h2>
          <ToggleShort
            label="Afficher angles"
            toggled={showAngle}
            onToggle={setShowAngle}
          />
        </h2>
        {showAngle && (
          <ComplementaryAnglesHint
            // highlightAngle={angle}
            wrapperClassName="grid grid-cols-2 sm:grid-cols-1 gap-2 mt-2"
          />
        )}
      </div>
    </div>
  );
};
