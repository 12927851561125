import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { LayoutLoading } from "~/components/LayoutLoading.tsx";
import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { useSettings } from "~/routes/suivi/providers/SettingsProvider.tsx";
import { supabase } from "~/supabase.ts";
import { Tables } from "~/supabase.types.ts";

interface GroupsContextType {
  groups: Tables<"suivi_groups">[];
  getGroup: (groupId: string) => Tables<"suivi_groups"> | undefined;
  refreshGroups: () => void;
}

const GroupsContext = createContext<GroupsContextType | undefined>(undefined);

export const useGroups = () => {
  const context = useContext(GroupsContext);

  if (context === undefined) {
    throw new Error("useGroups must be used within an GroupsProvider");
  }

  return context;
};

interface GroupsProviderProps {
  children: ReactNode;
}

export const GroupsProvider: React.FC<GroupsProviderProps> = ({ children }) => {
  const { user } = useCurrentUser();
  const { refreshSettings } = useSettings();

  const [groups, setGroups] = useState<Tables<"suivi_groups">[] | null>(null);

  const refreshGroups = useCallback(
    async () =>
      supabase
        .from("suivi_groups")
        .select("*")
        .eq("user_id", user.id)
        .order("name", { ascending: true })
        .then(({ data }) => {
          setGroups(data ?? []);
          refreshSettings();
        }),
    [refreshSettings, user.id],
  );

  const getGroup = (groupId: string) => groups?.find((g) => g.id === groupId);

  useEffect(() => {
    refreshGroups();
  }, [refreshGroups]);

  if (groups === null) {
    return <LayoutLoading />;
  }

  return (
    <GroupsContext.Provider value={{ groups, getGroup, refreshGroups }}>
      {children}
    </GroupsContext.Provider>
  );
};
