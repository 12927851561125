import React, { createContext, ReactNode, useContext, useState } from "react";
import secureLocalStorage from "react-secure-storage";

import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";

interface PilotestCredentialsContextType {
  hasPilotestAutoSyncEnabled: boolean;
  credentials: { email: string; password: string } | null;
  enablePilotestAutoSync: (email: string, password: string) => void;
  disablePilotestAutoSync: () => void;
}

const PilotestCredentialsContext = createContext<
  PilotestCredentialsContextType | undefined
>(undefined);

export const usePilotestCredentials = () => {
  const context = useContext(PilotestCredentialsContext);

  if (context === undefined) {
    throw new Error(
      "usePilotestCredentials must be used within an PilotestCredentialsProvider",
    );
  }

  return context;
};

interface PilotestCredentialsProviderProps {
  children: ReactNode;
}

export const PilotestCredentialsProvider: React.FC<
  PilotestCredentialsProviderProps
> = ({ children }) => {
  const { user } = useCurrentUser();

  const [hasPilotestAutoSyncEnabled, setHasPilotestAutoSyncEnabled] =
    useState<boolean>(
      !!secureLocalStorage.getItem(`suivi:pilotestCredentials:${user.id}`),
    );

  const [credentials, setCredentials] = useState(
    secureLocalStorage.getItem(
      `suivi:pilotestCredentials:${user.id}`,
    ) as PilotestCredentialsContextType["credentials"],
  );

  const handleEnableAutoSync = (email: string, password: string) => {
    secureLocalStorage.setItem(`suivi:pilotestCredentials:${user.id}`, {
      email,
      password,
    });

    setHasPilotestAutoSyncEnabled(true);
    setCredentials({ email, password });
  };

  const handleDisableAutoSync = () => {
    secureLocalStorage.removeItem(`suivi:pilotestCredentials:${user.id}`);

    setHasPilotestAutoSyncEnabled(false);
    setCredentials(null);
  };

  return (
    <PilotestCredentialsContext.Provider
      value={{
        hasPilotestAutoSyncEnabled,
        credentials,
        enablePilotestAutoSync: handleEnableAutoSync,
        disablePilotestAutoSync: handleDisableAutoSync,
      }}
    >
      {children}
    </PilotestCredentialsContext.Provider>
  );
};
