import { FC, Suspense } from "react";
import { Outlet } from "react-router-dom";

import { Title } from "~/routes/suivi/components/Title.tsx";
import { AttemptsProvider } from "~/routes/suivi/providers/AttemptsProvider.tsx";
import { FlaggedExercisesProvider } from "~/routes/suivi/providers/FlaggedExercisesProvider.tsx";
import { GroupsProvider } from "~/routes/suivi/providers/GroupsProvider.tsx";
import { ImportsProvider } from "~/routes/suivi/providers/ImportsProvider.tsx";
import { PilotestCredentialsProvider } from "~/routes/suivi/providers/PilotestCredentialsProvider.tsx";
import { RenamingsProvider } from "~/routes/suivi/providers/RenamingsProvider.tsx";
import { SettingsProvider } from "~/routes/suivi/providers/SettingsProvider.tsx";

export const Suivi: FC = () => (
  <SettingsProvider>
    <ImportsProvider>
      <PilotestCredentialsProvider>
        <AttemptsProvider>
          <GroupsProvider>
            <FlaggedExercisesProvider>
              <RenamingsProvider>
                <div className="flex flex-col px-4 xl:w-10/12 xl:mx-auto">
                  <Title />
                  <main className="flex-1 mt-4">
                    <Suspense>
                      <Outlet />
                    </Suspense>
                  </main>
                </div>
              </RenamingsProvider>
            </FlaggedExercisesProvider>
          </GroupsProvider>
        </AttemptsProvider>
      </PilotestCredentialsProvider>
    </ImportsProvider>
  </SettingsProvider>
);
