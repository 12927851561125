import { clsx } from "clsx";
import { useAtom } from "jotai/index";
import { last } from "lodash";
import { FC, useState } from "react";
import { useInterval } from "react-use";

import { BannerSupportScores } from "~/components/BannerSupportScores.tsx";
import { ScoreButton } from "~/components/ScoreButton.tsx";
import { SpeedSlider } from "~/components/SpeedSlider.tsx";
import { StartScreen } from "~/components/StartScreen.tsx";
import { Button } from "~/components/ui/button.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select.tsx";
import { Wrapper } from "~/components/Wrapper.tsx";
import { Exercise } from "~/exercise.ts";
import { useScores } from "~/hooks/useScores.ts";
import {
  memoryCalculsModeAtom,
  memoryCalculsSpeedAtom,
} from "~/routes/memory-calculs/atoms.ts";
import { MemoryCalculsAnswer } from "~/routes/memory-calculs/types.ts";
import {
  generateOperations,
  MemoryCalculsOperation,
} from "~/routes/memory-calculs/utils/generateOperations.ts";

export const MemoryCalculs: FC = () => {
  const { saveScore } = useScores();

  const [mode, setMode] = useAtom(memoryCalculsModeAtom);
  const [speed, setSpeed] = useAtom(memoryCalculsSpeedAtom);

  const [operations, setOperations] = useState<MemoryCalculsOperation[]>([]);

  const [displayedOperation, setDisplayedOperation] =
    useState<MemoryCalculsOperation | null>(null);
  const [displayActions, setDisplayActions] = useState(false);
  const [answers, setAnswers] = useState<boolean[]>([]);

  // Interval for displaying operations…
  useInterval(
    () => handleTick(),
    displayedOperation && !displayActions ? speed * 1000 : null,
  );

  // Interval for displaying actions…
  useInterval(
    () => handleTick(),
    displayedOperation && displayActions ? 2500 : null,
  );

  const handleStart = () => {
    const newOperations =
      mode === "m3b" ? generateOperations(33, 3) : generateOperations(22);

    setAnswers([]);
    setOperations(newOperations);
    setDisplayedOperation(newOperations[0]);
  };

  const handleTick = (answer: MemoryCalculsAnswer = "none") => {
    if (displayedOperation === null) {
      return;
    }

    if (displayedOperation.index < (mode === "mb3" ? 3 : 2)) {
      return setDisplayedOperation(operations[displayedOperation.index + 1]);
    }

    if (!displayActions) {
      return setDisplayActions(true);
    }

    const isCorrect =
      (operations[displayedOperation.index - (mode === "m3b" ? 3 : 2)]
        .result === displayedOperation.result &&
        answer === "identical") ||
      (operations[displayedOperation.index - (mode === "m3b" ? 3 : 2)]
        .result !== displayedOperation.result &&
        answer === "different");

    saveScore(Exercise.MemoryCalculs, `default:${mode}:${speed}`, {
      success: isCorrect,
      timing: 0,
    });

    setDisplayActions(false);
    setAnswers([...answers, isCorrect]);
    setDisplayedOperation(
      displayedOperation.index + 1 < operations.length
        ? operations[displayedOperation.index + 1]
        : null,
    );
  };

  return (
    <Wrapper
      header={
        <div className="flex gap-3">
          <ScoreButton
            exercise={Exercise.MemoryCalculs}
            label={`Memory Calculs (${speed} sec)`}
            variant={`default:${mode}:${speed}`}
            withTimings={false}
            filter={() => true}
          />
          <Select value={mode} onValueChange={(mode) => setMode(mode)}>
            <SelectTrigger>
              <SelectValue placeholder="Sélectionner un mode…" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="m2b">2 en arrière</SelectItem>
              {/*<SelectItem value="m3b">3 en arrière</SelectItem>*/}
            </SelectContent>
          </Select>
        </div>
      }
    >
      <BannerSupportScores exercise={Exercise.MemoryCalculs} threshold={50} />
      <div>
        <StartScreen
          started={!!displayedOperation}
          onStart={handleStart}
          description="À chaque étape, une opération s’affiche. Calculez son résultat, mémorisez-le puis comparez-le avec le résultat obtenu deux opérations plus tôt. Vous devez indiquer ensuite si les résultats sont identiques."
          header={
            answers.length > 0 ? (
              <div className="text-center font-medium text-lg">
                Résultats : {answers.filter(Boolean).length}/{answers.length}
              </div>
            ) : undefined
          }
          startButtonText={answers.length > 0 ? "Recommencer" : undefined}
          configuration={
            <SpeedSlider value={speed} min={2} max={5} onChange={setSpeed} />
          }
        >
          <div
            className={clsx(
              "grid grid-cols-1 sm:grid-cols-2 w-full",
              displayActions && "invisible",
            )}
          >
            <div
              className={clsx(
                "text-center py-4 sm:py-10 text-3xl font-semibold border rounded",
                (displayedOperation?.index ?? -1) % 2 === 0
                  ? "col-start-1"
                  : "sm:col-start-2",
              )}
            >
              {displayedOperation?.operation}
            </div>
          </div>
          <div
            className={clsx(
              "mx-auto my-4 w-8/12 h-2 rounded-md",
              displayActions && "invisible",
              answers.length > 0
                ? last(answers)
                  ? "bg-green-100"
                  : "bg-red-100"
                : "",
            )}
          />
          <div
            className={clsx(
              "flex flex-col justify-center sm:flex-row gap-4 sm:gap-6 mt-12",
              !displayActions && "invisible",
            )}
          >
            <Button
              variant="outline"
              size="lg"
              onClick={() => handleTick("identical")}
            >
              Identique
            </Button>
            <Button
              variant="outline"
              size="lg"
              onClick={() => handleTick("different")}
            >
              Différent
            </Button>
          </div>
        </StartScreen>
      </div>
    </Wrapper>
  );
};
