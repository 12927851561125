export enum Exercise {
  Alphabet = "alphabet",
  NombresPremiers = "nombres-premiers",
  BoitesMots = "boites-a-mots",
  Quizlet = "quizlet",
  CM1 = "cm1",
  CM2 = "cm2",
  Angles = "angles",
  Tangram = "tangram",
  Memory = "memory",
  MemoryCalculs = "memory-calculs",
}
