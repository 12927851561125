import { clsx } from "clsx";
import { isSameDay } from "date-fns";
import { debounce } from "lodash";
import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Input } from "~/components/ui/input.tsx";
import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { Role } from "~/role.ts";
import { AdminBlock } from "~/routes/admin/components/AdminBlock.tsx";
import { AdminDrawerUser } from "~/routes/admin/components/AdminDrawerUser.tsx";
import { AdminHeaderPanel } from "~/routes/admin/components/AdminHeaderPanel.tsx";
import { AdminUser } from "~/routes/admin/components/AdminUser.tsx";
import { supabase } from "~/supabase.ts";
import { Database } from "~/supabase.types.ts";
import { formatDate } from "~/utils/formatDate.ts";
import { formatNumber } from "~/utils/formatNumber.ts";

const AdminWithProviders: FC = () => {
  const [openUserId, setOpenUserId] = useState<string | null>(null);

  const [query, setQuery] = useState("");
  const [queryUsers, setQueryUsers] = useState<
    Database["public"]["Functions"]["private_search_users"]["Returns"] | null
  >(null);

  const [userCount, setUserCount] = useState<number | null>(null);
  const [userSuiviCount, setUserSuiviCount] = useState<number | null>(null);
  const [eplTestUsersCount, setEplTestUsersCount] = useState<number | null>(
    null,
  );
  const [scoresCount, setScoresCount] = useState<number | null>(null);
  const [donationsCount, setDonationsCount] = useState<number | null>(null);

  const [lastUsers, setLastUsers] = useState<
    Database["public"]["Functions"]["private_get_last_users"]["Returns"] | null
  >(null);
  const [topAttempts, setTopAttempts] = useState<
    Database["public"]["Functions"]["private_get_top_scores"]["Returns"] | null
  >(null);
  const [topScores, setTopScores] = useState<
    Database["public"]["Functions"]["private_get_top_scores"]["Returns"] | null
  >(null);
  const [lastScores, setLastScores] = useState<
    Database["public"]["Functions"]["private_get_last_scores"]["Returns"] | null
  >(null);
  const [lastImports, setLastImports] = useState<
    | Database["public"]["Functions"]["private_get_last_imports"]["Returns"]
    | null
  >(null);

  useEffect(() => {
    // Get All Users count…
    supabase
      .rpc("private_get_users_count")
      .then(({ data }) => setUserCount(data ?? 0));

    // Get Suivi Users count…
    supabase
      .from("suivi_settings")
      .select("*", { count: "exact", head: true })
      .then(({ count }) => setUserSuiviCount(count ?? 0));

    supabase
      .from("suivi_settings")
      .select("*", { count: "exact", head: true })
      .not("epl_api_key", "is", null)
      .then(({ count }) => setEplTestUsersCount(count ?? 0));

    // Get Scores count…
    supabase
      .from("scores")
      .select("*", { count: "exact", head: true })
      .then(({ count }) => setScoresCount(count ?? 0));

    // Get Donations count…
    supabase
      .from("donations")
      .select("*", { count: "exact", head: true })
      .then(({ count }) => setDonationsCount(count ?? 0));

    // Get Last Users…
    supabase
      .rpc("private_get_last_users", { l: 5 })
      .then(({ data }) => setLastUsers(data ?? []));

    // Get Top Attempts…
    supabase
      .rpc("private_get_top_attempts", { l: 5 })
      .then(({ data }) => setTopAttempts(data ?? []));

    // Get Top Scores…
    supabase
      .rpc("private_get_top_scores", { l: 5 })
      .then(({ data }) => setTopScores(data ?? []));

    // Get Last Scores…
    supabase
      .rpc("private_get_last_scores", { l: 20 })
      .then(({ data }) => setLastScores(data ?? []));

    // Get Last Imports…
    supabase
      .rpc("private_get_last_imports", { l: 20 })
      .then(({ data }) => setLastImports(data ?? []));

    supabase
      .rpc("public_get_stanines", {
        from_date: "2023-12-01",
        until_date: "2024-11-05",
      })
      .gt("attempts", 10)
      .then(console.log);
  }, []);

  const debouncedFetchResults = useCallback(
    debounce((value: string) => {
      if (value.trim()) {
        void supabase
          .rpc("private_search_users", { search_query: value })
          .then(({ data }) => setQueryUsers(data));
      } else {
        setQueryUsers([]);
      }
    }, 500),
    [],
  );

  const handleOnSearchUser = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    debouncedFetchResults(e.target.value);
  };

  const handleOnClickUser = (userId: string) => {
    setOpenUserId(userId);
  };

  return (
    <>
      <div className="sm:px-4 flex flex-col gap-4 sm:gap-6">
        <div>
          <Input
            type="search"
            value={query}
            onChange={handleOnSearchUser}
            placeholder="Rechercher un utilisateur…"
            className="h-12 text-lg"
          />
          {query.trim().length > 0 && (queryUsers?.length ?? 0) > 0 && (
            <ul className="mt-2.5 ml-2 space-y-0.5">
              {queryUsers?.map((queryUser) => (
                <li key={`search-${queryUser.id}`}>
                  <AdminUser
                    user_id={queryUser.id}
                    onClick={handleOnClickUser}
                    {...queryUser}
                  />
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-5 gap-4 sm:gap-6">
          <AdminHeaderPanel title="Utilisateurs Global" value={userCount} />
          <AdminHeaderPanel title="Utilisateurs Suivi" value={userSuiviCount} />
          <AdminHeaderPanel
            title="Utilisateurs Suivi EPLtest"
            value={eplTestUsersCount}
          />
          <AdminHeaderPanel title="Essais KD Tools" value={scoresCount} />
          <AdminHeaderPanel title="Dons" value={donationsCount} />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 sm:gap-6">
          <AdminBlock title="Derniers Inscrits">
            <table className="w-full">
              <tbody>
                {lastUsers?.map((user) => (
                  <tr
                    key={user.user_id}
                    className={clsx(
                      isSameDay(new Date(user.created_at), new Date())
                        ? "font-semibold bg-yellow-50"
                        : "even:bg-slate-50",
                    )}
                  >
                    <td className="text-sm px-2 py-1">
                      <AdminUser onClick={handleOnClickUser} {...user} />
                    </td>
                    <td className="text-right tabular-nums text-sm px-2">
                      {formatDate(user.created_at)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </AdminBlock>
          <AdminBlock title="Top Essais Pilotest">
            <table className="w-full">
              <tbody>
                {topAttempts?.map((score, idx) => (
                  <tr key={`score-${idx}`} className={clsx("even:bg-slate-50")}>
                    <td className="text-right text-xs tabular-nums">
                      {idx + 1}
                    </td>
                    <td className="text-sm px-2 py-1">
                      <AdminUser onClick={handleOnClickUser} {...score} />
                    </td>
                    <td className="text-right tabular-nums font-mono text-sm px-2">
                      {formatNumber(score.count)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </AdminBlock>
          <AdminBlock title="Top Scores">
            <table className="w-full">
              <tbody>
                {topScores?.map((score, idx) => (
                  <tr key={`top-score-${idx}`} className="even:bg-slate-50">
                    <td className="text-right text-xs tabular-nums">
                      {idx + 1}
                    </td>
                    <td className="text-sm px-2 py-1">
                      <AdminUser onClick={handleOnClickUser} {...score} />
                    </td>
                    <td className="text-right tabular-nums font-mono text-sm px-2">
                      {formatNumber(score.count)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </AdminBlock>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
          <AdminBlock title="Derniers Imports">
            <table className="w-full">
              <tbody>
                {lastImports?.map((anImport, idx) => (
                  <tr
                    key={`import-${idx}`}
                    className={clsx(
                      isSameDay(new Date(anImport.last_at), new Date())
                        ? "font-semibold bg-yellow-50"
                        : "even:bg-slate-50",
                    )}
                  >
                    <td
                      className={clsx("text-right tabular-nums text-sm px-2")}
                    >
                      {formatDate(anImport.last_at, "date")}
                    </td>
                    <td className="text-sm px-2 py-1">
                      <AdminUser onClick={handleOnClickUser} {...anImport} />
                    </td>
                    <td className="px-2 text-sm">{anImport.source}</td>
                    <td className="px-2 text-sm text-right font-mono tabular-nums">
                      +{anImport.attempts_added_sum}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </AdminBlock>
          <AdminBlock title="Derniers Scores">
            <table className="w-full">
              <tbody>
                {lastScores?.map((score, idx) => (
                  <tr
                    key={`last-score-${idx}`}
                    className={clsx(
                      isSameDay(new Date(score.at), new Date())
                        ? "font-semibold bg-yellow-50"
                        : "even:bg-slate-50",
                    )}
                  >
                    <td className="text-right tabular-nums text-sm px-2">
                      {formatDate(score.at, "date")}
                    </td>
                    <td className="text-sm px-2 py-1">
                      <AdminUser onClick={handleOnClickUser} {...score} />
                    </td>
                    <td className="px-2 text-sm">
                      <span className="font-medium">{score.exercise}</span>{" "}
                      <span className="text-slate-400">{score.variant}</span>
                    </td>
                    <td className="px-2 text-sm text-right font-mono tabular-nums">
                      {score.count}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </AdminBlock>
        </div>
      </div>
      <AdminDrawerUser
        userId={openUserId}
        onDismiss={() => setOpenUserId(null)}
      />
    </>
  );
};

export const Admin = () => {
  const navigate = useNavigate();

  const { canAccess } = useCurrentUser();

  useEffect(() => {
    if (!canAccess(Role.Admin)) {
      return navigate("/");
    }
  }, [canAccess, navigate]);

  if (!canAccess(Role.Admin)) {
    return null;
  }

  return <AdminWithProviders />;
};
