import { clsx } from "clsx";
import { FC, ReactNode } from "react";

import { useDonateLink } from "~/hooks/useDonateLink.ts";

interface LinkDonateProps {
  children: ReactNode;
  wrapperClassname?: string;
  openTab?: boolean;
}

export const LinkDonate: FC<LinkDonateProps> = ({
  children,
  wrapperClassname,
  openTab = true,
}) => {
  const donateLink = useDonateLink();

  return (
    <a
      className={clsx("underline hover:no-underline", wrapperClassname)}
      href={donateLink}
      target={openTab ? "_blank" : undefined}
      data-umami-event="global:donate"
    >
      {children}
    </a>
  );
};
