import { SettingsIcon } from "lucide-react";
import { FC, useCallback, useEffect, useState } from "react";

import { LayoutLoading } from "~/components/LayoutLoading.tsx";
import { ScoreButton } from "~/components/ScoreButton.tsx";
import { Button } from "~/components/ui/button.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select.tsx";
import { Wrapper } from "~/components/Wrapper.tsx";
import { Exercise } from "~/exercise.ts";
import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { Role } from "~/role.ts";
import { Admin } from "~/routes/quizlet/Admin.tsx";
import { Password } from "~/routes/quizlet/Password.tsx";
import { Questions } from "~/routes/quizlet/Questions.tsx";
import { supabase } from "~/supabase.ts";
import { Tables } from "~/supabase.types.ts";

export type QuizletFilter = "allQuestions" | "bookmarked" | "mine";

interface QuizletWithSettingsProps {
  settings: Tables<"quizlet_settings"> | null;
  refreshSettings: () => void;
}

const QuizletWithSettings: FC<QuizletWithSettingsProps> = ({
  settings,
  refreshSettings,
}) => {
  const { user, canAccess } = useCurrentUser();

  const [filter, setFilter] = useState<QuizletFilter>("allQuestions");
  const [adminMode, setAdminMode] = useState(false);

  const handleOnUnlock = async () => {
    supabase
      .from("quizlet_settings")
      .insert({ user_id: user.id, unlocked_at: new Date().toISOString() })
      .then(refreshSettings);
  };

  return (
    <Wrapper
      extended={adminMode}
      header={
        <>
          {settings && (
            <div className="flex items-center justify-center gap-3">
              {canAccess(Role.AdminQuizlet) && (
                <Button
                  variant="ghost-destructive"
                  onClick={() => setAdminMode(!adminMode)}
                >
                  <SettingsIcon />
                  <span className="ml-2">Admin</span>
                </Button>
              )}
              <ScoreButton
                exercise={Exercise.Quizlet}
                variant={filter}
                label="Culture G"
                withTimings={false}
                filter={() => true}
              />
              <Select
                value={filter}
                onValueChange={(filter) => setFilter(filter as QuizletFilter)}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Sélectionner une catégorie…" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="allQuestions">
                    Toutes les questions
                  </SelectItem>
                  <SelectItem value="bookmarked">Mes favoris</SelectItem>
                  <SelectItem value="mine">Mes questions</SelectItem>
                </SelectContent>
              </Select>
            </div>
          )}
        </>
      }
    >
      {canAccess(Role.AdminQuizlet) && adminMode ? (
        <Admin />
      ) : settings ? (
        <Questions filter={filter} />
      ) : (
        <Password onUnlock={handleOnUnlock} />
      )}
    </Wrapper>
  );
};

export const Quizlet = () => {
  const { user } = useCurrentUser();

  const [quizletSettings, setQuizletSettings] = useState<
    Tables<"quizlet_settings"> | undefined | null
  >(undefined);

  const getSettings = useCallback(() => {
    supabase
      .from("quizlet_settings")
      .select()
      .eq("user_id", user.id)
      .limit(1)
      .maybeSingle()
      .then(({ data }) => {
        setQuizletSettings(data);
      });
  }, [user.id]);

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  if (quizletSettings === undefined) {
    return <LayoutLoading />;
  }

  return (
    <QuizletWithSettings
      settings={quizletSettings}
      refreshSettings={getSettings}
    />
  );
};
