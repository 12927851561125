import { FC, useState } from "react";

import { Button } from "~/components/ui/button.tsx";
import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { SettingsGroup } from "~/routes/suivi/queries/settingsQuery.ts";
import { supabase } from "~/supabase.ts";

interface DimensionsInitializerProps {
  group: SettingsGroup;
  onSubmit: () => void;
}

type DimensionsInitializerStatus =
  | "idle"
  | "save_dimension"
  | "configure_dimension";

export const DimensionsInitializer: FC<DimensionsInitializerProps> = ({
  group,
  onSubmit,
}) => {
  const { user } = useCurrentUser();

  const [status, setStatus] = useState<DimensionsInitializerStatus>("idle");

  const boostrapDimensions = async () => {
    setStatus("save_dimension");

    const { data } = await supabase
      .from("suivi_dimensions")
      .insert({
        user_id: user.id,
        group_id: group.id,
        compute: "average_5_attempts",
      })
      .select("id")
      .maybeSingle();

    if (!data) {
      return setStatus("idle");
    }

    const { id: dimensionId } = data;

    const { error } = await supabase
      .from("suivi_dimensions_dimensions")
      .insert([
        {
          user_id: user.id,
          dimension_id: dimensionId,
          name: "Attention",
          icon: "scan-eye",
        },
        {
          user_id: user.id,
          dimension_id: dimensionId,
          name: "Numérique",
          icon: "sigma",
        },
        {
          user_id: user.id,
          dimension_id: dimensionId,
          name: "Raisonnement",
          icon: "brain",
        },
        {
          user_id: user.id,
          dimension_id: dimensionId,
          name: "Spatial",
          icon: "box",
        },
        {
          user_id: user.id,
          dimension_id: dimensionId,
          name: "Multitâches",
          icon: "joystick",
        },
        {
          user_id: user.id,
          dimension_id: dimensionId,
          name: "Verbal",
          icon: "message-square",
        },
      ]);

    onSubmit();

    setStatus(error ? "idle" : "configure_dimension");
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="py-10 text-center">
        <p className="text-sm max-w-3xl text-center leading-6 text-slate-500">
          Les dimensions vous permettent d'afficher vos résultats pour une{" "}
          <i>dimension</i> (Attention, Numérique, Raisonnement, Spatial,
          Multitâches et Verbal) donnée, comme représenté à l'ENAC pour les
          Cadets.
        </p>
        <p className="mt-6">
          <Button
            onClick={boostrapDimensions}
            disabled={status === "save_dimension"}
          >
            Configurer pour {group.name}…
          </Button>
        </p>
      </div>
    </div>
  );
};
