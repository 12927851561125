import { FC, ReactNode } from "react";

import { Button } from "~/components/ui/button.tsx";
import { useMatchingRoute } from "~/hooks/useMatchingRoute.ts";

interface StartScreenProps {
  children: ReactNode;
  description: ReactNode;
  onStart: () => void;
  title?: string;
  header?: ReactNode;
  started?: boolean;
  startButtonText?: string;
  configuration?: ReactNode;
}

export const StartScreen: FC<StartScreenProps> = ({
  children,
  description,
  onStart,
  title,
  header,
  started,
  startButtonText,
  configuration,
}) => {
  const exerciseFromRoute = useMatchingRoute();

  if (started) {
    return children;
  }

  return (
    <div className="flex flex-col items-center">
      {header ?? (
        <>
          <h3 className="font-semibold text-lg inline-flex items-center gap-2">
            {exerciseFromRoute.icon && (
              <exerciseFromRoute.icon className="w-5 h-5" />
            )}
            {title ?? exerciseFromRoute.title}
          </h3>
          <div className="text-slate-400 text-sm mt-2 max-w-lg">
            {description}
          </div>
        </>
      )}
      <Button onClick={onStart} className="mt-10">
        {startButtonText ?? "Commencer"}
      </Button>
      {configuration}
    </div>
  );
};
