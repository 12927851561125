import { set } from "date-fns";
import { useAtom, useAtomValue } from "jotai";
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { useEffectOnce, usePrevious } from "react-use";

import { LayoutLoading } from "~/components/LayoutLoading.tsx";
import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import {
  suiviAllAttempts,
  suiviEplTestAttempts,
  suiviPilotestAttempts,
} from "~/routes/suivi/atoms.ts";
import { useImports } from "~/routes/suivi/providers/ImportsProvider.tsx";
import { usePilotestCredentials } from "~/routes/suivi/providers/PilotestCredentialsProvider.tsx";
import { useSettings } from "~/routes/suivi/providers/SettingsProvider.tsx";
import { ExerciseAttempt } from "~/routes/suivi/types/ExerciseAttempt.ts";
import { PilotestAttemptApiJson } from "~/routes/suivi/types/PilotestAttempt.ts";
import { sanitizeEplAttempt } from "~/routes/suivi/utils.ts";
import { supabase } from "~/supabase.ts";
import { Enums } from "~/supabase.types.ts";

interface AttemptsState {
  attempts: ExerciseAttempt[];
  pilotestSyncing: boolean;
  pilotestAttempts: ExerciseAttempt[];
  eplTestSyncing: boolean;
  eplTestAttempts: ExerciseAttempt[];
}

interface AttemptsContextType extends AttemptsState {
  checkPilotestConnectivity: (email: string, password: string) => Promise<void>;
  syncPilotest: () => PromiseLike<void>;
  retrieveResultsFromPilotest: () => Promise<unknown>;
  savePilotestAttempts: (
    source: Enums<"import_source">,
    newAttempts: ExerciseAttempt[],
  ) => Promise<void>;
  checkEplTestConnectivity: (apiKey: string) => Promise<void>;
  syncEplTest: () => Promise<unknown>;
  deleteEplTestAttempts: () => void;
}

const AttemptsContext = createContext<AttemptsContextType | undefined>(
  undefined,
);

export const useAttempts = () => {
  const context = useContext(AttemptsContext);

  if (context === undefined) {
    throw new Error("useAttempts must be used within an AttemptsProvider");
  }

  return context;
};

interface AttemptsProviderProps {
  children: ReactNode;
}

export const AttemptsProvider: React.FC<AttemptsProviderProps> = ({
  children,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { settings } = useSettings();
  const { saveImport } = useImports();
  const { user, accessToken } = useCurrentUser();
  const { hasPilotestAutoSyncEnabled, credentials } = usePilotestCredentials();

  const prevEplTestSyncEnabled = usePrevious(settings?.epl_api_key);
  const prevHasPilotestAutoSyncEnabled = usePrevious(
    hasPilotestAutoSyncEnabled,
  );

  const attempts = useAtomValue(suiviAllAttempts);
  const [pilotestSyncing, setPilotestSyncing] = useState(false);
  const [pilotestAttempts, setPilotestAttempts] = useAtom(
    suiviPilotestAttempts,
  );
  const [eplTestSyncing, setEplTestSyncing] = useState(false);
  const [eplTestAttempts, setEplTestAttempts] = useAtom(suiviEplTestAttempts);

  const checkPilotestConnectivity = useCallback(
    (email: string, password: string) =>
      fetch("https://api-kd.valentin.xyz/pilotest/results", {
        headers: {
          "X-Supabase-Token": accessToken,
          "X-PT-U": btoa(email),
          "X-PT-P": btoa(password),
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.error) {
            return Promise.reject(data.error);
          }

          return Promise.resolve();
        }),
    [accessToken],
  );

  const syncPilotest = () => {
    setPilotestSyncing(true);

    return supabase
      .from("suivi_attempts")
      .select("provider, exercise, percent_score, stanine_class, at")
      .eq("user_id", user.id)
      .then(({ data }) => {
        setPilotestSyncing(false);
        setPilotestAttempts(
          data?.map((d) => ({
            name: `${d.exercise}##PT`,
            stanineClass: d.stanine_class,
            percentScore: d.percent_score,
            at: new Date(d.at),
          })) ?? [],
        );
      });
  };

  const savePilotestAttempts = async (
    source: Enums<"import_source">,
    newAttempts: ExerciseAttempt[],
  ) =>
    supabase
      .from("suivi_attempts")
      .upsert(
        newAttempts.map((attempt) => ({
          user_id: user.id,
          provider: "pilotest" as const,
          exercise: attempt.name,
          percent_score: attempt.percentScore,
          stanine_class: attempt.stanineClass,
          at: set(new Date(attempt.at), {
            seconds: 0,
            milliseconds: 0,
          }).toISOString(),
        })),
        { ignoreDuplicates: true },
      )
      .select()
      .then(({ data }) => {
        saveImport(source, "pilotest", data?.length);
        return syncPilotest();
      });

  const retrieveResultsFromPilotest = () => {
    if (!hasPilotestAutoSyncEnabled) {
      return Promise.resolve();
    }

    if (!credentials) {
      return Promise.reject();
    }

    setPilotestSyncing(true);

    const { email, password } = credentials;

    return fetch("https://api-kd.valentin.xyz/pilotest/results", {
      headers: {
        "X-Supabase-Token": accessToken,
        "X-PT-U": btoa(email),
        "X-PT-P": btoa(password),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          return Promise.reject();
        }

        return data.map((a: PilotestAttemptApiJson) => ({
          name: a.name,
          percentScore: a.percent_score,
          stanineClass: a.stanine_class,
          at: new Date(a.at),
        }));
      })
      .then((res) => savePilotestAttempts("auto_sync", res));
  };

  const syncEplTest = () => {
    if (!settings?.epl_api_key) {
      setEplTestAttempts([]);
      return Promise.resolve([]);
    }

    setEplTestSyncing(true);

    return fetch(
      `https://epltest.fr/public_api/instances?locale=fr&public_api_key=${encodeURIComponent(
        settings.epl_api_key,
      )}`,
    )
      .then((res) => res.json())
      .then((res) => res.map(sanitizeEplAttempt))
      .then(setEplTestAttempts)
      .then(() => setEplTestSyncing(false))
      .catch(() => []);
  };

  const deleteEplTestAttempts = () => setEplTestAttempts([]);

  const checkEplTestConnectivity = (apiKey: string) =>
    fetch(
      `https://epltest.fr/public_api/instances?locale=fr&public_api_key=${encodeURIComponent(
        apiKey,
      )}`,
    ).then((res) =>
      res.ok ? Promise.resolve() : Promise.reject("sync:could_not_fetch"),
    );

  useEffectOnce(() => {
    Promise.all([syncPilotest(), syncEplTest()]).then(
      retrieveResultsFromPilotest,
    );
  });

  // This one is used to get Pilotest results when auto-sync is enabled…
  useEffect(() => {
    if (
      prevHasPilotestAutoSyncEnabled === false &&
      hasPilotestAutoSyncEnabled
    ) {
      retrieveResultsFromPilotest();
    }
  }, [
    hasPilotestAutoSyncEnabled,
    prevHasPilotestAutoSyncEnabled,
    retrieveResultsFromPilotest,
  ]);

  // This one is used to get EPLtest results when sync is enabled…
  useEffect(() => {
    if (prevEplTestSyncEnabled === null && !!settings?.epl_api_key) {
      syncEplTest();
    }
  }, [prevEplTestSyncEnabled, settings?.epl_api_key]);

  useEffect(() => {
    if (searchParams.has("onboarded")) {
      syncEplTest().then(() => {
        searchParams.delete("onboarded");
        setSearchParams(searchParams);
      });
    }
  }, [searchParams]);

  if (pilotestAttempts === null || eplTestAttempts === null) {
    return <LayoutLoading />;
  }

  return (
    <AttemptsContext.Provider
      value={{
        attempts,
        pilotestSyncing,
        pilotestAttempts,
        eplTestSyncing,
        eplTestAttempts,
        checkPilotestConnectivity,
        syncPilotest,
        retrieveResultsFromPilotest,
        savePilotestAttempts,
        checkEplTestConnectivity,
        syncEplTest,
        deleteEplTestAttempts,
      }}
    >
      {children}
    </AttemptsContext.Provider>
  );
};
