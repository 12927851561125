import { Trash2Icon } from "lucide-react";
import { FC, useEffect, useState } from "react";
import Select, {
  ClearIndicatorProps,
  ControlProps,
  IndicatorsContainerProps,
  MenuListProps,
  MultiValue,
  MultiValueProps,
  OptionProps,
  PlaceholderProps,
  ValueContainerProps,
} from "react-select";

import { ExerciseWithIcon } from "~/routes/suivi/components/ExerciseWithIcon.tsx";
import { useAttempts } from "~/routes/suivi/providers/AttemptsProvider.tsx";
import { useRenamings } from "~/routes/suivi/providers/RenamingsProvider.tsx";
import { getAllTakenExerciseNames } from "~/routes/suivi/utils/getAllTakenExerciseNames.ts";

export interface ExercisesSelectOption {
  value: string;
  customName?: string;
}

const SelectValueContainer = (
  props: ValueContainerProps<ExercisesSelectOption, true>,
) => (
  <div className="flex flex-wrap shrink items-center gap-1 px-2 py-1">
    {props.children}
  </div>
);

const SelectMultiValue = (
  props: MultiValueProps<ExercisesSelectOption, true>,
) => (
  <p
    className="group flex items-center gap-2 px-2 py-1 text-sm border rounded cursor-pointer bg-slate-50"
    {...props.removeProps}
  >
    <ExerciseWithIcon
      exerciseName={props.data.value}
      customName={props.data.customName}
      withLink={false}
    />
    <span className="group-hover:text-red-500">
      <Trash2Icon className="w-3 h-3" />
    </span>
  </p>
);

const SelectOption = (props: OptionProps<ExercisesSelectOption, true>) => (
  <p
    className="flex items-center gap-2 px-3 py-1 rounded hover:bg-slate-100 cursor-default"
    {...props.innerProps}
  >
    <ExerciseWithIcon
      exerciseName={props.data.value}
      customName={props.data.customName}
      withLink={false}
    />
  </p>
);

const SelectMenuList = (props: MenuListProps<ExercisesSelectOption, true>) => (
  <div className="flex flex-col gap-1 px-2.5 py-2 max-h-80 overflow-y-scroll">
    {props.children}
  </div>
);

const SelectClearIndicator = (
  props: ClearIndicatorProps<ExercisesSelectOption, true>,
) => (
  <button
    className="text-slate-400 hover:text-slate-500 cursor-pointer"
    onClick={props.clearValue}
  >
    <Trash2Icon className="w-4 h-4 mr-2" />
  </button>
);

const SelectControl = (props: ControlProps<ExercisesSelectOption, true>) => (
  <div
    className="border rounded flex justify-between items-center"
    {...props.innerProps}
  >
    {props.children}
  </div>
);

const SelectIndicatorsContainer = (
  props: IndicatorsContainerProps<ExercisesSelectOption, true>,
) => (
  <div className="flex shrink-0" onClick={props.clearValue}>
    {props.children}
  </div>
);

const SelectPlaceholder = (
  props: PlaceholderProps<ExercisesSelectOption, true>,
) => (
  <div className="text-sm absolute text-slate-400 ml-0.5">{props.children}</div>
);

interface ExercisesSelectProps {
  value: MultiValue<ExercisesSelectOption>;
  onChange: (newValue: MultiValue<ExercisesSelectOption>) => void;
  customOptions?: ExercisesSelectOption[];
}

export const ExercisesSelect: FC<ExercisesSelectProps> = ({
  value,
  onChange,
  customOptions,
}) => {
  const { attempts } = useAttempts();
  const { renamingsList } = useRenamings();

  const [options, setOptions] = useState<ExercisesSelectOption[]>(
    customOptions ?? [],
  );

  useEffect(() => {
    if (customOptions) {
      return;
    }

    setOptions(
      getAllTakenExerciseNames(attempts).map((a) => ({
        value: a,
        customName: renamingsList[a],
      })),
    );
  }, [attempts, customOptions, renamingsList]);

  return (
    <Select
      options={options}
      value={value}
      onChange={onChange}
      placeholder="Choisir des exercices…"
      classNamePrefix="_select"
      noOptionsMessage={() => <span>Aucun exercice disponible</span>}
      closeMenuOnSelect={false}
      isMulti
      components={{
        Placeholder: SelectPlaceholder,
        ValueContainer: SelectValueContainer,
        MultiValue: SelectMultiValue,
        Option: SelectOption,
        MenuList: SelectMenuList,
        ClearIndicator: SelectClearIndicator,
        IndicatorsContainer: SelectIndicatorsContainer,
        Control: SelectControl,
      }}
    />
  );
};
