import { FC, useMemo } from "react";

import { DimensionAverage } from "~/routes/suivi/components/Dimensions/DimensionAverage.tsx";
import { DimensionHeader } from "~/routes/suivi/components/Dimensions/DimensionHeader.tsx";
import { DimensionsDimension } from "~/routes/suivi/components/Dimensions/DimensionsDimension.tsx";
import { useAttempts } from "~/routes/suivi/providers/AttemptsProvider.tsx";
import { useSettings } from "~/routes/suivi/providers/SettingsProvider.tsx";
import { DimensionWithExercises } from "~/routes/suivi/queries/dimensionsWithExercisesQuery.ts";
import { computeDimensionWithAverages } from "~/routes/suivi/utils/computeDimensionWithAverages.ts";

interface DimensionBlockProps {
  dimension: DimensionWithExercises;
}

export const DimensionBlock: FC<DimensionBlockProps> = ({ dimension }) => {
  const { attempts } = useAttempts();
  const { settings } = useSettings();

  const dimensionAverages = useMemo(() => {
    if (dimension.hidden) {
      return [];
    }

    return computeDimensionWithAverages(
      dimension.dimensions,
      attempts,
      dimension.compute,
      settings?.display_from_date ?? "",
    );
  }, [
    attempts,
    dimension.compute,
    dimension.dimensions,
    dimension.hidden,
    settings?.display_from_date,
  ]);

  if (dimension.hidden) {
    return null;
  }

  return (
    <div className="px-4 py-3 flex flex-col gap-1">
      <DimensionHeader />
      <DimensionAverage dimensions={dimensionAverages} />
      {dimensionAverages.map((dimension) => (
        <DimensionsDimension key={dimension.id} dimension={dimension} />
      ))}
    </div>
  );
};
