import { BannerSupport } from "~/components/BannerSupport.tsx";
import { useImports } from "~/routes/suivi/providers/ImportsProvider.tsx";
import { useSettings } from "~/routes/suivi/providers/SettingsProvider.tsx";

export const BannerSupportSuivi = () => {
  const { imports } = useImports();
  const { settings } = useSettings();

  if (!settings || imports.length <= 15) {
    return null;
  }

  return (
    <BannerSupport>
      Depuis votre inscription, vous avez réalisé{" "}
      <span className="font-semibold">{imports.length} imports</span> pour
      suivre votre progression sur KD Tools !
    </BannerSupport>
  );
};
