import { FC, useState } from "react";

import { ExerciseAttempt } from "~/routes/suivi/types/ExerciseAttempt.ts";
import { handleCsvUpload } from "~/routes/suivi/utils/handleCsvUpload.ts";
import { handleJsonUpload } from "~/routes/suivi/utils/handleJsonUpload.ts";
import { Enums } from "~/supabase.types.ts";

export interface UploadCsvBoxProps {
  onDrop?: () => void;
  onResults: (
    source: Enums<"import_source">,
    results?: ExerciseAttempt[],
  ) => void;
}

export const UploadCsvBox: FC<UploadCsvBoxProps> = ({ onDrop, onResults }) => {
  const [randomKey, setRandomKey] = useState(crypto.randomUUID());

  return (
    <div>
      <div className="hidden sm:flex flex-col border px-4 py-3 pb-4 rounded shadow-sm">
        <small className="font-semibold mb-2">
          <a
            id="btn-download-pilotest-csv"
            href="https://www.pilotest.com/fr/results.csv"
            rel="noreferrer"
            className="underline hover:no-underline"
            target="_blank"
          >
            Télécharger
          </a>{" "}
          et importer un nouvel export Pilotest :
        </small>
        <input
          id="csvFile"
          type="file"
          name="csvFile"
          accept=".csv"
          className="text-sm mt-1"
          key={`csv-${randomKey}`}
          onChange={(evt) => {
            onDrop?.();
            handleCsvUpload(evt, (res) => {
              onResults("input_csv", res);
              setTimeout(() => setRandomKey(crypto.randomUUID()), 2000);
            });
          }}
        />
        <input
          id="jsonFile"
          type="file"
          name="jsonFile"
          accept=".json"
          className="hidden"
          key={`json-${randomKey}`}
          onChange={(evt) => {
            onDrop?.();
            handleJsonUpload(evt, (res) => {
              onResults("tamper_json", res);
              setTimeout(() => setRandomKey(crypto.randomUUID()), 2000);
            });
          }}
        />
      </div>
      {/*<p className="hidden sm:block text-xs mt-1.5 text-slate-600">*/}
      {/*  Power user ?{" "}*/}
      {/*  <a*/}
      {/*    href="https://greasyfork.org/en/scripts/511211-synchroniser-kd-suivi-avec-pilotest"*/}
      {/*    target="_blank"*/}
      {/*    className="underline hover:no-underline"*/}
      {/*  >*/}
      {/*    Installez le script Tampermonkey*/}
      {/*  </a>{" "}*/}
      {/*  !*/}
      {/*</p>*/}
    </div>
  );
};
