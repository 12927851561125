import { FC } from "react";

import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { Footer } from "~/routes/suivi/components/Footer.tsx";

export const Root: FC = () => {
  const { user } = useCurrentUser();

  return (
    <div className="flex flex-col items-center justify-between h-full">
      <div className="flex flex-col items-center justify-center flex-1">
        <span className="text-6xl">👋</span>
        <p className="text-2xl font-medium mt-6 text-slate-600">
          Bienvenue{" "}
          <span className="text-slate-800 underline underline-offset-4">{`${user?.user_metadata?.full_name ?? user.user_metadata?.email ?? ""}`}</span>{" "}
          !
        </p>
        <p className="text-center text-slate-400 text-sm mt-2 w-10/12 sm:w-full">
          Utilisez le menu de gauche pour naviguer entre les différents outils.
        </p>
      </div>
      <Footer />
    </div>
  );
};
