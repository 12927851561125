import clsx from "clsx";
import { random } from "lodash";
import { FC, useEffect, useMemo, useState } from "react";

import { generateClockwiseAndCounterClockwise } from "./utils.ts";

import { AnglesDirection } from "~/routes/angles/types.ts";

interface ClockProps {
  angle: number;
  onClick: () => void;
  className?: string;
  start?: number;
  direction?: AnglesDirection;
  selected?: boolean;
}

export const Clock: FC<ClockProps> = ({
  angle = 0,
  start,
  direction = "clockwise",
  selected = false,
  onClick,
  className,
}) => {
  const [rotation, setRotation] = useState(random(0, 359));

  const positions = useMemo(
    () => generateClockwiseAndCounterClockwise(start),
    [start],
  );

  useEffect(() => {
    setRotation(random(0, 359));
  }, [angle]);

  return (
    <div
      onClick={onClick}
      className={clsx(
        "border border-black rounded relative cursor-pointer select-none",
        selected && "border-blue-500 border-4",
        className,
      )}
    >
      <svg
        preserveAspectRatio="xMidYMid meet"
        viewBox="-10 -10 120 120"
        style={{
          transformOrigin: "center",
          transformBox: "fill-box",
          transform: `rotate(${rotation}deg)`,
        }}
      >
        <g transform="">
          <circle
            cx="50"
            cy="50"
            r="40"
            stroke="black"
            strokeWidth="1"
            fill="none"
          />
          <g>
            {/*<line*/}
            {/*  x1="80"*/}
            {/*  y1="50"*/}
            {/*  x2="90"*/}
            {/*  y2="50"*/}
            {/*  stroke="black"*/}
            {/*  strokeWidth="1"*/}
            {/*/>*/}
            <text
              x="80"
              y="50"
              textAnchor="middle"
              dominantBaseline="middle"
              fontSize={7}
            >
              {positions[direction][1]}
            </text>
          </g>
          <g>
            {/*<line*/}
            {/*  x1="50"*/}
            {/*  y1="80"*/}
            {/*  x2="50"*/}
            {/*  y2="90"*/}
            {/*  stroke="black"*/}
            {/*  strokeWidth="1"*/}
            {/*/>*/}
            <text
              x="50"
              y="80"
              textAnchor="middle"
              dominantBaseline="middle"
              fontSize={7}
            >
              {positions[direction][2]}
            </text>
          </g>
          <g>
            {/*<line*/}
            {/*  x1="20"*/}
            {/*  y1="50"*/}
            {/*  x2="10"*/}
            {/*  y2="50"*/}
            {/*  stroke="black"*/}
            {/*  strokeWidth="1"*/}
            {/*/>*/}
            <text
              x="20"
              y="50"
              textAnchor="middle"
              dominantBaseline="middle"
              fontSize={7}
            >
              {positions[direction][3]}
            </text>
          </g>
          <g>
            {/*<line*/}
            {/*  x1="50"*/}
            {/*  y1="20"*/}
            {/*  x2="50"*/}
            {/*  y2="10"*/}
            {/*  stroke="black"*/}
            {/*  strokeWidth="1"*/}
            {/*/>*/}
            <text
              x="50"
              y="20"
              textAnchor="middle"
              dominantBaseline="middle"
              fontSize={7}
            >
              {positions[direction][0]}
            </text>
          </g>
        </g>
      </svg>
      <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
        <p className="text-xl font-medium">{angle}</p>
      </div>
    </div>
  );
};
