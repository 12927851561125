import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";
import { useEffectOnce } from "react-use";

import { LayoutLoading } from "~/components/LayoutLoading.tsx";
import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { supabase } from "~/supabase.ts";
import { Tables } from "~/supabase.types.ts";

interface DonationsContextType {
  donations: Pick<Tables<"donations">, "id" | "created_at">[];
}

const DismissalsContext = createContext<DonationsContextType | undefined>(
  undefined,
);

export const useDonations = () => {
  const context = useContext(DismissalsContext);

  if (context === undefined) {
    throw new Error("useDonations must be used within a DonationsProvider");
  }

  return context;
};

interface DonationsProviderProps {
  children: ReactNode;
}

export const DonationsProvider: React.FC<DonationsProviderProps> = ({
  children,
}) => {
  const { user } = useCurrentUser();

  const [donations, setDonations] = useState<
    DonationsContextType["donations"] | null
  >(null);

  const refreshDonationsCallback = useCallback(
    () =>
      supabase
        .from("donations")
        .select("id, created_at")
        .eq("user_id", user.id)
        .then(({ data }) => setDonations(data ?? [])),
    [user.id],
  );

  useEffectOnce(() => {
    refreshDonationsCallback();
  });

  if (donations === null) {
    return <LayoutLoading />;
  }

  return (
    <DismissalsContext.Provider value={{ donations }}>
      {children}
    </DismissalsContext.Provider>
  );
};
