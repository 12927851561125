import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { BannerSupportSuivi } from "~/routes/suivi/components/BannerSupportSuivi.tsx";
import { Charts } from "~/routes/suivi/components/Charts.tsx";
import { Dimensions } from "~/routes/suivi/components/Dimensions";
import { ExercisesResults } from "~/routes/suivi/components/ExercisesResults.tsx";
import { Navigation } from "~/routes/suivi/components/Navigation.tsx";
import { useSettings } from "~/routes/suivi/providers/SettingsProvider.tsx";

export const StatsPage: FC = () => {
  const navigate = useNavigate();

  const { settings } = useSettings();

  useEffect(() => {
    if (!settings) {
      return navigate("/suivi/onboarding");
    }
  }, [navigate, settings]);

  if (!settings) {
    return null;
  }

  return (
    <div>
      <BannerSupportSuivi />
      <Navigation />
      <section className="space-y-8 sm:space-y-10 mt-8 sm:mt-10">
        <ExercisesResults />
        <Dimensions />
        <Charts />
      </section>
    </div>
  );
};
