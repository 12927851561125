import { useAtom } from "jotai/index";
import { FC } from "react";

import { BannerSupportScores } from "~/components/BannerSupportScores.tsx";
import { ScoreButton } from "~/components/ScoreButton.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select.tsx";
import { Wrapper } from "~/components/Wrapper.tsx";
import { Exercise } from "~/exercise.ts";
import { AnglesView } from "~/routes/angles/AnglesView.tsx";
import { anglesModeAtom } from "~/routes/angles/atoms.ts";
import { ComplementaryAnglesView } from "~/routes/angles/ComplementaryAnglesView.tsx";
import { GridView } from "~/routes/angles/GridView.tsx";

export type ANGLES_MODES = "default" | "complementary_angles" | "angles";

export const Angles: FC = () => {
  const [mode, setMode] = useAtom(anglesModeAtom);

  return (
    <Wrapper
      header={
        <div className="flex gap-3">
          <ScoreButton
            label="Angles"
            exercise={Exercise.Angles}
            variant={mode}
          />
          <Select
            value={mode}
            onValueChange={(mode) => setMode(mode as ANGLES_MODES)}
          >
            <SelectTrigger>
              <SelectValue placeholder="Sélectionner un mode…" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="default">Examen</SelectItem>
              <SelectItem value="complementary_angles">
                Angles Complémentaires
              </SelectItem>
              <SelectItem value="angles">Angles</SelectItem>
            </SelectContent>
          </Select>
        </div>
      }
    >
      <BannerSupportScores exercise={Exercise.Angles} />
      {mode === "complementary_angles" ? (
        <ComplementaryAnglesView />
      ) : mode === "angles" ? (
        <AnglesView />
      ) : (
        <GridView />
      )}
    </Wrapper>
  );
};
