import { FC } from "react";

import { Wrapper } from "~/components/Wrapper.tsx";
import { IntegerDivision } from "~/routes/mathematiques/IntegerDivision.tsx";
import { Pgcd } from "~/routes/mathematiques/Pgcd.tsx";
import { Ppcm } from "~/routes/mathematiques/Ppcm.tsx";

export const Mathematiques: FC = () => (
  <Wrapper>
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-20 gap-y-12">
      <Pgcd />
      <Ppcm />
      <IntegerDivision />
    </div>
  </Wrapper>
);
