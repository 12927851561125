import { format } from "date-fns";
import { RefreshCwIcon, TrashIcon } from "lucide-react";
import { FC, useState } from "react";

import { SyncStatus } from "~/components/SyncStatus.tsx";
import { Button } from "~/components/ui/button.tsx";
import { useAttempts } from "~/routes/suivi/providers/AttemptsProvider.tsx";
import { useSettings } from "~/routes/suivi/providers/SettingsProvider.tsx";

export const TitleStatusEplTest: FC = () => {
  const { settings, saveSettings } = useSettings();
  const { eplTestSyncing, syncEplTest, deleteEplTestAttempts } = useAttempts();

  const [importDate, setImportDate] = useState(new Date());

  const handleEnableEplTest = async () => {
    const eplApiKey = prompt(
      "Veuillez saisir votre clé API EPLtest pour activer la synchronisation automatique de vos résultats EPLtest.\n\nVous pouvez récupérer votre clé depuis le site EPLtest : \nhttps://epltest.fr/fr/account/public_api",
    );

    if (!eplApiKey) {
      return;
    }

    saveSettings({ epl_api_key: eplApiKey });
  };

  const handleDisableEplTest = () => {
    if (
      confirm(
        "Êtes-vous sur de vouloir désactiver l'intégration EPLtest ? Vous perdrez l'ensemble de vos résultats EPLtest.",
      )
    ) {
      saveSettings({ epl_api_key: null }).then(deleteEplTestAttempts);
    }
  };

  if (!settings?.epl_api_key) {
    return (
      <p className="text-sm text-slate-400 inline-flex items-center gap-2">
        <SyncStatus />
        <span className="text-slate-300">
          Import <strong className="font-semibold">EPLtest</strong> désactivé
        </span>
        <Button size="xs" variant="outline" onClick={handleEnableEplTest}>
          Activer EPLtest
        </Button>
      </p>
    );
  }

  return (
    <div className="text-sm text-slate-400 flex items-center gap-2 h-6">
      <SyncStatus color={eplTestSyncing ? "blue" : "green"} />
      <div>
        Import <strong className="font-medium">EPLtest</strong>{" "}
        {eplTestSyncing ? (
          "en cours…"
        ) : (
          <>du {format(importDate, "dd/MM à HH:mm")}</>
        )}
      </div>
      {!eplTestSyncing && (
        <div className="flex items-center gap-1.5">
          <Button
            size="xs"
            variant="ghost"
            onClick={() => {
              syncEplTest().then(() => setImportDate(new Date()));
            }}
            disabled={eplTestSyncing}
          >
            <RefreshCwIcon className="w-3.5 h-3.5" />
          </Button>
          <Button
            size="xs"
            variant="ghost-destructive"
            onClick={handleDisableEplTest}
          >
            <TrashIcon className="w-3.5 h-3.5" />
          </Button>
        </div>
      )}
    </div>
  );
};
