import clsx from "clsx";
import { FC, ReactNode } from "react";

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "~/components/ui/tooltip.tsx";
import { ExerciseWithIcon } from "~/routes/suivi/components/ExerciseWithIcon.tsx";
import { useRenamings } from "~/routes/suivi/providers/RenamingsProvider.tsx";
import { DimensionWithAverages } from "~/routes/suivi/types/DimensionWithAverages.ts";
import { textClassNameFromStanineClass } from "~/routes/suivi/utils/textClassNameFromStanineClass.ts";

interface DimensionsDimensionTooltipProps {
  children: ReactNode;
  className?: string;
  dimension: DimensionWithAverages;
}

export const DimensionsDimensionTooltip: FC<
  DimensionsDimensionTooltipProps
> = ({ dimension, children, className }) => {
  const { renamingsList } = useRenamings();

  return (
    <Tooltip delayDuration={250}>
      <TooltipTrigger
        className={clsx(
          "font-medium flex items-center gap-2 cursor-default",
          className,
        )}
      >
        {children}
      </TooltipTrigger>
      <TooltipContent>
        <div className="w-64 pb-1">
          <p className="text-sm font-medium border-b border-slate-600 pt-1 pb-1">
            {dimension.name}
          </p>
          <div className="flex flex-col gap-1 mt-2">
            {dimension.exercises.length === 0 && (
              <p>Aucun exercice de cette dimension réalisé.</p>
            )}
            {dimension.exercises.map((ex) => (
              <div
                key={ex.name}
                className="flex items-center justify-between gap-4"
              >
                <p className="inline-flex items-center gap-1 truncate">
                  <ExerciseWithIcon
                    exerciseName={ex.name}
                    customName={renamingsList[ex.name]}
                    withLink={false}
                  />
                </p>
                <p
                  className={clsx(
                    "tabular-nums font-semibold inline-flex items-center",
                    textClassNameFromStanineClass(ex.averageStanineClass),
                  )}
                >
                  <span>{ex.averageStanineClass.toFixed(1)}</span>
                </p>
              </div>
            ))}
            <div />
          </div>
        </div>
      </TooltipContent>
    </Tooltip>
  );
};
