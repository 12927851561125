import clsx from "clsx";
import groupBy from "lodash/groupBy";
import { ChartNoAxesCombinedIcon, SettingsIcon } from "lucide-react";
import { FC, useRef } from "react";

import { ButtonHide } from "~/components/ButtonHide.tsx";
import { Button } from "~/components/ui/button.tsx";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "~/components/ui/dropdown-menu.tsx";
import { Chart } from "~/routes/suivi/components/Chart.tsx";
import { useAggregatedResultsFromDate } from "~/routes/suivi/hooks/useAggregatedResultsFromDate.ts";
import { useAttempts } from "~/routes/suivi/providers/AttemptsProvider.tsx";
import { useSettings } from "~/routes/suivi/providers/SettingsProvider.tsx";
import { sortExerciseNames } from "~/routes/suivi/utils/sortExerciseNames.ts";
import { Enums } from "~/supabase.types.ts";

export const Charts: FC = () => {
  const { attempts } = useAttempts();
  const { settings, saveSettings } = useSettings();

  const ref = useRef<HTMLDivElement | null>(null);

  const allResultsByDate = useAggregatedResultsFromDate(
    settings?.display_from_date ?? "",
  );

  const exercisesDisplayed = sortExerciseNames(
    Object.keys(groupBy(attempts, (attempt) => attempt.name)),
    settings?.current_group?.exercises ?? [],
    settings?.current_group?.use_custom_order ?? false,
  );

  if (exercisesDisplayed.length === 0) {
    return null;
  }

  return (
    <div className="border rounded" ref={ref}>
      <div className="flex items-center justify-between border-b px-3 py-1.5 bg-slate-100">
        <p className="font-medium text-2xl inline-flex items-center gap-2 pl-1">
          <ChartNoAxesCombinedIcon className="text-slate-600" />
          <span>Progression</span>
        </p>
        <div className="hidden sm:flex space-x-3">
          <ButtonHide
            hidden={settings?.graph_hidden}
            onToggle={(newValue) => saveSettings({ graph_hidden: newValue })}
          />
          {!settings?.graph_hidden && (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline">
                  <SettingsIcon className="w-4 h-4" />
                  <span className="ml-2">Paramètres</span>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-56">
                <DropdownMenuLabel>Mesure</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuRadioGroup
                  value={settings?.graph_display ?? "stanine"}
                  onValueChange={(display) =>
                    saveSettings({
                      graph_display: display as Enums<"graph_display">,
                    })
                  }
                >
                  <DropdownMenuRadioItem
                    value="stanine"
                    onSelect={(evt) => evt.preventDefault()}
                  >
                    Stanine
                  </DropdownMenuRadioItem>
                  <DropdownMenuRadioItem
                    value="percentage"
                    onSelect={(evt) => evt.preventDefault()}
                  >
                    Pourcentage
                  </DropdownMenuRadioItem>
                </DropdownMenuRadioGroup>
                <DropdownMenuLabel>Calcul</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuRadioGroup
                  value={settings?.graph_compute ?? "compute"}
                  onValueChange={(compute) =>
                    saveSettings({
                      graph_compute: compute as Enums<"graph_compute">,
                    })
                  }
                >
                  <DropdownMenuRadioItem
                    value="max"
                    onSelect={(evt) => evt.preventDefault()}
                  >
                    Meilleur score
                  </DropdownMenuRadioItem>
                  <DropdownMenuRadioItem
                    value="mean"
                    onSelect={(evt) => evt.preventDefault()}
                  >
                    Moyenne
                  </DropdownMenuRadioItem>
                </DropdownMenuRadioGroup>
                <DropdownMenuLabel>Affichage</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuRadioGroup
                  value={settings?.graph_columns?.toString() ?? "4"}
                  onValueChange={(columns) =>
                    saveSettings({
                      graph_columns: parseInt(columns),
                    })
                  }
                >
                  <DropdownMenuRadioItem
                    value="3"
                    onSelect={(evt) => evt.preventDefault()}
                  >
                    3 colonnes
                  </DropdownMenuRadioItem>
                  <DropdownMenuRadioItem
                    value="4"
                    onSelect={(evt) => evt.preventDefault()}
                  >
                    4 colonnes
                  </DropdownMenuRadioItem>
                  <DropdownMenuRadioItem
                    value="5"
                    onSelect={(evt) => evt.preventDefault()}
                  >
                    5 colonnes
                  </DropdownMenuRadioItem>
                </DropdownMenuRadioGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </div>
      </div>
      {!settings?.graph_hidden && (
        <div
          className={clsx(
            "grid grid-cols-1 gap-8 sm:px-4 sm:py-2",
            settings?.graph_columns === 3
              ? "sm:grid-cols-3"
              : settings?.graph_columns === 5
                ? "sm:grid-cols-5"
                : "sm:grid-cols-4",
          )}
        >
          {exercisesDisplayed.map((exercise) => (
            <Chart
              key={exercise}
              display={settings?.graph_display ?? "stanine"}
              exercise={exercise}
              results={allResultsByDate.filter((r) =>
                Object.prototype.hasOwnProperty.call(r, exercise),
              )}
            />
          ))}
        </div>
      )}
    </div>
  );
};
