import clsx from "clsx";
import { meanBy } from "lodash";
import { FC } from "react";

import { DimensionsDimensionGradient } from "~/routes/suivi/components/Dimensions/DimensionsDimensionGradient.tsx";
import { DimensionWithAverages } from "~/routes/suivi/types/DimensionWithAverages.ts";
import { textClassNameFromStanineClass } from "~/routes/suivi/utils/textClassNameFromStanineClass.ts";

interface DimensionAverageProps {
  dimensions: DimensionWithAverages[];
}

export const DimensionAverage: FC<DimensionAverageProps> = ({
  dimensions = [],
}) => {
  const includedDimensions = dimensions.filter(
    (dim) => dim.averageStanineClass !== 0,
  );

  const averageStanineClass =
    includedDimensions.length === 0
      ? 0
      : meanBy(includedDimensions, "averageStanineClass");

  return (
    <div className="flex items-center gap-2 mb-2">
      <div className="w-2/12 text-sm font-semibold">Moyenne</div>
      <div className="grow relative">
        <DimensionsDimensionGradient
          stanineClass={averageStanineClass}
          size="lg"
        />
      </div>
      <div>
        <p
          className={clsx(
            "pl-2 tabular-nums",
            textClassNameFromStanineClass(averageStanineClass),
          )}
        >
          {averageStanineClass.toFixed(1)}
        </p>
      </div>
    </div>
  );
};
