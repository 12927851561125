import clsx from "clsx";
import { addSeconds } from "date-fns";
import { FC, useEffect, useState } from "react";
import { useTimer } from "react-timer-hook";

import { Button } from "~/components/Button.tsx";
import { TitleLabel } from "~/components/TitleLabel.tsx";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "~/components/ui/tooltip.tsx";
import { Exercise } from "~/exercise.ts";
import { useScores } from "~/hooks/useScores.ts";
import { BOITE_MOTS_APP_MODE } from "~/routes/boites-mots/index.tsx";
import {
  getGuessWordWithThemes,
  GuessWordWithThemes,
} from "~/routes/boites-mots/utils.ts";
import { Tables } from "~/supabase.types.ts";
import { popupWindow } from "~/utils/popupWindow.tsx";

interface TrainingViewProps {
  mode: BOITE_MOTS_APP_MODE;
  themes: Tables<"boites_mots_themes">[];
}

const MAX_SECONDS = 5;

export const TrainingView: FC<TrainingViewProps> = ({ mode, themes }) => {
  const [flipState, setFlipState] = useState(true);

  const { totalSeconds, restart } = useTimer({
    expiryTimestamp: new Date(),
    onExpire: () =>
      mode === "TIMED_TRAINING" ? handleClickWord("NO_ANSWER") : {},
    autoStart: false,
  });
  const { saveScore } = useScores();

  const [currentGuess, setCurrentGuess] = useState<ReturnType<
    typeof getGuessWordWithThemes
  > | null>(null);
  const [pastGuesses, setPastGuesses] = useState<GuessWordWithThemes[]>([]);

  const handleClickWord = (theme: string) => {
    if (!currentGuess) {
      return;
    }

    saveScore(Exercise.BoitesMots, "default", {
      success: theme === currentGuess.guess.theme,
      timing: 0,
    });

    setPastGuesses([
      ...pastGuesses,
      {
        theme: currentGuess.guess.theme,
        word: currentGuess.guess.word,
        success: theme === currentGuess.guess.theme,
      },
    ]);

    setCurrentGuess(getGuessWordWithThemes(themes));
    setFlipState(!flipState);
  };

  useEffect(() => {
    setCurrentGuess(getGuessWordWithThemes(themes));
  }, [themes]);

  useEffect(() => {
    if (mode === "TIMED_TRAINING") {
      restart(addSeconds(new Date(), MAX_SECONDS));
    }
  }, [mode, restart]);

  useEffect(() => {
    restart(addSeconds(new Date(), MAX_SECONDS));
  }, [flipState, restart]);

  return (
    <>
      {currentGuess && (
        <div className="grid grid-cols-1 gap-4">
          <div>
            <TitleLabel className="mb-1">Mot</TitleLabel>
            <div className="overflow-hidden rounded-lg bg-slate-50">
              <div className="px-3 py-3 h-28 flex justify-center items-center">
                {mode === "FREE_TRAINING" ? (
                  <Tooltip>
                    <TooltipTrigger
                      className="text-2xl font-semibold underline decoration-dotted cursor-help"
                      onClick={() => {
                        popupWindow(
                          `https://fr.wikipedia.org/w/index.php?search=${currentGuess?.guess.word}`,
                        );
                      }}
                    >
                      {currentGuess?.guess.word}
                    </TooltipTrigger>
                    <TooltipContent>
                      Chercher "{currentGuess?.guess.word}" sur Wikipedia
                    </TooltipContent>
                  </Tooltip>
                ) : (
                  currentGuess?.guess.word
                )}
              </div>
            </div>
          </div>
          <div>
            <h2 className="mb-1 flex justify-between">
              <TitleLabel>Thème</TitleLabel>
              {mode === "TIMED_TRAINING" && (
                <span className="text-slate-500 tabular-nums cursor-pointer">
                  ⌛ {totalSeconds}
                </span>
              )}
            </h2>
            <div className="overflow-hidden rounded-lg bg-slate-50">
              <div className="px-4 py-4 flex flex-wrap justify-center items-center gap-4">
                {currentGuess.themes.map((theme) => (
                  <Button
                    key={`theme-${theme}`}
                    onClick={() => handleClickWord(theme)}
                  >
                    {theme}
                  </Button>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      {pastGuesses.length > 0 && (
        <div className="col-span-2 mt-12">
          <h2 className="mb-1 flex justify-between">
            <span className="uppercase font-medium text-sm text-slate-500 tracking-wider">
              Résultats
            </span>
            <span className="text-sm tabular-nums">
              {pastGuesses.filter((g) => g.success).length}/{pastGuesses.length}
            </span>
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-2 mb-8">
            {[...pastGuesses].reverse().map((w, idx) => (
              <div
                key={`past-${idx}`}
                className={clsx(
                  "flex items-center space-x-1 px-2 py-2 rounded",
                  w.success ? "bg-green-100" : "bg-red-100",
                )}
              >
                <Tooltip>
                  <TooltipTrigger
                    className="font-medium underline decoration-dotted cursor-help"
                    onClick={() => {
                      popupWindow(
                        `https://fr.wikipedia.org/w/index.php?search=${w.word}`,
                      );
                    }}
                  >
                    {w.word}
                  </TooltipTrigger>
                  <TooltipContent>
                    Chercher "{w.word}" sur Wikipedia
                  </TooltipContent>
                </Tooltip>
                :<span className="font-medium">{w.theme}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
